import React, { FC, useMemo } from 'react';
import List from '@mui/material/List';
import { useAppDispatch, useAppSelector } from '../../store';
import { toggleTocChapterOpen } from '../../store/actions';
import { tocOutlineSelector } from '../../store/selectors';
import ChapterListItem from './ChapterListItem';
import styles from './styles';

interface TableOfContentsMenuProps {
	onPageSelect: (pageId: string) => void;
}

const TableOfContentsMenu: FC<TableOfContentsMenuProps> = ({ onPageSelect }) => {
	const dispatch = useAppDispatch();

	const manifest = useAppSelector((state) => state.manifest);
	const outline = useMemo(() => tocOutlineSelector(manifest), [manifest]);
	const chaptersOpen = useAppSelector((state) => state.tocMenu.chaptersOpen);

	const handleChapterToggle = (chapterId: string) => dispatch(toggleTocChapterOpen(chapterId));

	return (
		<List classes={{ root: styles }}>
			{outline.chapters.map((chapterOutline) => (
				<ChapterListItem
					key={chapterOutline.id}
					chapterOutline={chapterOutline}
					open={chaptersOpen[chapterOutline.id]}
					onChapterToggle={handleChapterToggle}
					onPageSelect={onPageSelect}
				/>
			))}
		</List>
	);
};

export default TableOfContentsMenu;
