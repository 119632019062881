import React, { FC } from 'react';
import { isEmpty } from 'lodash-es';

import { basePage } from 'components/legends/progress_view_legend';
import ScoreTableBox from 'components/tables/ScoreTableBox';
import styles from 'components/legends/styles';
import { useAppSelector } from 'store';

const complete = {
	...basePage,
	questionsCorrect: 1,
	questionsScored: 1,
};

export const lowScore = {
	...basePage,
	questionsCorrect: 1,
	questionsCompleted: 3,
	questionsPossible: 4,
	questionsScored: 4,
};

const lowScorePastDue = {
	...basePage,
	questionsCorrect: 1,
	questionsCompleted: 3,
	questionsPossible: 4,
	questionsScored: 4,
	pastDue: true,
};

const notAttempted = {
	...basePage,
	pageOpened: false,
	questionsScored: 1,
};

const notAttemptedPastDue = {
	...basePage,
	pageOpened: false,
	pastDue: true,
	questionsScored: 1,
};

const ScoreViewLegend: FC = () => {
	const notebookSummary = useAppSelector((state) => state.notebookSummary);
	const dueDatesPresent = !isEmpty(notebookSummary.dueDates);

	return (
		<div className={styles}>
			<span>
				<ScoreTableBox pageSummary={complete} />
				<span aria-hidden="true">All correct</span>
			</span>
			<span>
				<ScoreTableBox pageSummary={lowScore} />
				<span aria-hidden="true">Low score (some attempts remain)</span>
			</span>
			<span>
				<ScoreTableBox pageSummary={lowScorePastDue} />
				<span aria-hidden="true">Low score (no attempts remain)</span>
			</span>
			<span>
				<ScoreTableBox pageSummary={notAttempted} />
				<span aria-hidden="true">Page not yet visited</span>
			</span>
			{dueDatesPresent && (
				<span>
					<ScoreTableBox pageSummary={notAttemptedPastDue} />
					<span aria-hidden="true">
						<span className="no-wrap">Due date has passed</span>
					</span>
				</span>
			)}
		</div>
	);
};

export default ScoreViewLegend;
