import { css } from 'emotion';
import { colors } from 'styles/theme';
import { fadeIn } from 'styles/mixins/fade';

export default css`
	color: black;
	height: 100%;
	padding: 1rem;
	text-align: center;
	background: white;
	overflow-y: auto;
	border: none;
	background-size: cover;
	background-position: center center !important;

	.welcome-card {
		color: white;
		margin: 48px auto auto;
		max-width: 728px;
		min-height: 356px;
		padding: 1rem;
		background: rgba(11, 21, 44, 0.86);
		box-shadow: 4px 5px 16px 0 rgba(0, 0, 0, 0.5);
		backdrop-filter: blur(10px);

		hr {
			max-width: 72px;
			margin-top: 16px;
			margin-bottom: 16px;
			border: thin solid white;
		}

		h2 {
			font-family: Helvetica, Arial, sans-serif;
			font-weight: bold;
			font-size: 17px;
		}

		h3 {
			font-size: 17px;
			font-weight: normal;
			margin-bottom: 1rem;
		}

		p {
			font-size: 14px;
		}

		.welcome-card-body {
			display: flex;
			padding: 1rem;
			justify-content: space-evenly;
			align-items: flex-start;

			.progress-tip {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				margin-top: 16px;
				max-width: 270px;
				flex-basis: 100%;

				p {
					margin: 0;
					max-width: 100%;
				}

				.progress-icon {
					width: 72px;
					height: 72px;
					display: flex;
					align-items: center;
					justify-content: center;
					margin-bottom: 1rem;
					border: 6px solid #668ffc;
				}
			}

			.score-tip {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				max-width: 270px;
				margin-top: 16px;
				flex-basis: 100%;

				p {
					margin: 0;
					max-width: 100%;
				}

				.score-icon {
					font-weight: bold;
					width: 72px;
					height: 72px;
					display: flex;
					font-size: 18px;
					align-items: center;
					justify-content: center;
					color: #668ffc;
					margin-bottom: 1rem;
					border: 6px solid #668ffc;
				}
			}

			.vertical-line {
				width: 2px;
				margin: auto;
				height: 190px;
				max-width: 100%;
				background-color: #d8d8d8;
				opacity: 0.4;
				content: ' ';
			}
		}

		.welcome-loader {
			border: 2px solid white;
			border-radius: 8px;
			padding: 2px;
			background: transparent;
			max-width: 312px;
			margin: 16px auto 24px;

			.progress-bar {
				background: white;
			}
		}

		.user-inputs {
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: 14px;
			margin-top: 18px;
			margin-bottom: 18px;
			${fadeIn(400)};

			.begin-button {
				width: 88px;
				height: 40px;
				font-weight: normal;
				text-transform: uppercase;
				font-size: 12px;
			}

			.welcome-screen-toggle {
				color: white;

				.MuiSvgIcon-root {
					font-size: 18px;
				}
			}

			.welcome-screen-toggle-checked {
				color: ${colors.lavender};
			}

			.welcome-screen-toggle-label {
				font-family: inherit;
				font-size: 13px;
			}
		}

		.error-message {
			color: ${colors.pleasantRed};

			.begin-button {
				margin-top: 0;
				width: initial;
			}
		}
	}
`;
