import React, { FC } from 'react';
import cn from 'classnames';

import PopupQuizNotebookView, {
	transformAnswerForNotebookView,
} from '@soomo/lib/components/pageElements/PopupQuiz/NotebookView';
import StudentAnswers from '@soomo/lib/components/pageElements/PopupQuiz/StudentAnswers';
import { ElementAnswer, PopupQuizAnswer } from '@soomo/lib/notebook/types';
import { PopUpQuizElement } from '@soomo/lib/types/WebtextManifest';

import elementStyles from 'components/page_view/elements/element_styles';
import IconCorrectness from 'components/page_view/elements/IconCorrectness';
import withLibsThemeProvider from 'hocs/withLibsThemeProvider';

interface PopUpQuizProps {
	element: PopUpQuizElement;
	answers: ElementAnswer[];
}

const PopUpQuiz: FC<PopUpQuizProps> = ({ element, answers }) => {
	const puqAnswers: PopupQuizAnswer[] = answers?.map(transformAnswerForNotebookView) || [];

	const latestAnswer = puqAnswers[puqAnswers.length - 1];
	const results = latestAnswer && !latestAnswer.reset_at && latestAnswer.data;

	const hasNonResetAnswers = puqAnswers.some((answer) => !answer.reset_at);
	const showStudentAnswers = element.config.ui?.myprogress?.showStudentAnswers;

	return (
		<div className={cn('page-element popup-quiz-element', elementStyles)}>
			<div className="element-content">
				<div className="element-activity-header">
					<span className="element-type">Self Assessment</span>
				</div>
				<h2
					className="element-body"
					dangerouslySetInnerHTML={{ __html: element.config.name }}
				/>
				<div className="element-results">
					{results?.completed && hasNonResetAnswers && <IconCorrectness />}
				</div>
				<PopupQuizNotebookView
					popupQuiz={element.config}
					questions={element.config.questions}
					answers={puqAnswers}
				/>
				{showStudentAnswers && results?.completed && (
					<StudentAnswers questions={element.config.questions} answers={puqAnswers} />
				)}
			</div>
		</div>
	);
};

export default withLibsThemeProvider(PopUpQuiz);
