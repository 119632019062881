import { css } from 'emotion';
import { fadeIn } from 'styles/mixins/fade';

const styles = css`
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	${fadeIn(300)}
	.scroll-container {
		overflow: auto;
		flex-grow: 1;
	}

	.legend {
		border-top: thin solid lightgrey;
		overflow-x: auto;
		flex-shrink: 0;
		flex-grow: 0;
	}
`;

export default styles;
