import React, { FC, ReactElement } from 'react';
import { DateTime } from 'luxon';
import { IconCheck } from 'components/icons';
import { NotebookPageSummary } from '@soomo/lib/notebook/types';
import { getPageProgressType } from '../page_type_categorizer';
import cn from 'classnames';
import DashedLine from '../DashedLine';
import { visuallyHidden } from 'styles/utils';
import { completionFraction, scoreFraction } from '@soomo/lib/notebook/utils';
import { clamp } from '@soomo/lib/utils';
import styles from './styles';

interface ProgressTableBox {
	pageSummary: NotebookPageSummary;
}

enum ProgressElementTypeAriaLabel {
	'complete' = 'Complete',
	'partially-complete' = 'Incomplete',
	'no-longer-completable' = 'No longer completable',
	'no-longer-attemptable' = 'No attempts remain',
	'not-attempted' = 'Page not yet visited',
}

const ProgressTableBox: FC<ProgressTableBox> = ({ pageSummary }) => {
	const {
		questionsPossible,
		questionsCompleted,
		questionsScored,
		resetsRemaining,
		limitedAttempts,
		pastDue,
		dueAt: pageDueAt,
		penaltyPeriodEndsAt: pagePenaltyPeriodEndsAt,
	} = pageSummary;

	const dueAt = pageDueAt ? DateTime.fromISO(pageDueAt) : null;
	const penaltyPeriodEndsAt = pageDueAt ? DateTime.fromISO(pagePenaltyPeriodEndsAt) : null;
	const now = DateTime.local();

	const inInvisiblePenaltyPeriod = dueAt ? dueAt <= now && now < penaltyPeriodEndsAt : false;
	const outOfWorkWindow = pastDue && !inInvisiblePenaltyPeriod;

	let allWorkComplete: boolean;
	if (questionsScored === 0) {
		// There are no multiple choice questions on the page
		// So they're done as soon as every question has an answer
		allWorkComplete = questionsCompleted === questionsPossible;
	} else {
		// If there are MC questions, and no more resets, and they've answered every question
		allWorkComplete =
			limitedAttempts && resetsRemaining === 0 && questionsCompleted === questionsPossible;
	}
	const noLongerCompletable = outOfWorkWindow || allWorkComplete;

	const pageType = getPageProgressType({
		noLongerCompletable,
		...pageSummary,
	});

	let element: ReactElement = null;
	switch (pageType) {
		case 'complete':
			element = <IconCheck />;
			break;
		case 'partially-complete':
			const adjustedPercent = clamp(completionFraction(pageSummary), 0.23, 0.77);
			element = <div className="fill-box" style={{ height: `${adjustedPercent * 100}%` }} />;
			break;
		case 'no-longer-completable': {
			if (questionsPossible > 0) {
				const adjustedPercent = clamp(completionFraction(pageSummary), 0.23, 0.77);
				element = <div className="fill-box" style={{ height: `${adjustedPercent * 100}%` }} />;
			}
			break;
		}
		case 'no-longer-attemptable':
			// todo: Re-write this logic for clarity now that the spec has deviated
			element = questionsPossible > 0 ? <DashedLine /> : null;
			break;
		case 'not-attempted':
			// todo: Re-write this logic for clarity now that the spec has deviated
			element = questionsPossible > 0 ? <div className="line red" /> : null;
			break;
	}

	let altText: string;
	switch (pageType) {
		case 'partially-complete':
			// "Incomplete, N% remaining"; see T-103930
			altText = `${ProgressElementTypeAriaLabel[pageType]}, ${
				100 - clamp(Math.round(completionFraction(pageSummary) * 100), 0, 100)
			}% remaining`;
			break;
		default:
			altText = ProgressElementTypeAriaLabel[pageType];
	}

	return (
		<div className={cn(styles, pageType)}>
			{element}
			<span className={visuallyHidden}>{altText}</span>
		</div>
	);
};

export default ProgressTableBox;
