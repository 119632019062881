import { css } from 'emotion';
import { colors } from 'styles/theme';
import { lighten } from 'polished';

export const textSkeletonMixin = (color = colors.evilGrey, duration = '666ms') => css`
	animation: skeleton-pulse ${duration} linear infinite alternate;
	@keyframes skeleton-pulse {
		0% {
			color: ${color};
		}
		100% {
			color: ${lighten(0.3, color)};
		}
	}
`;
