import { css } from 'emotion';

export default css`
	display: flex;
	align-items: center;
	margin-top: 32px;
	width: 100%;
	position: relative;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	justify-content: center;
`;
