import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { DateTime } from 'luxon';
import RolloverChapterContent from 'components/rollovers/rollover_chapter_content';
import RolloverMessageContent from 'components/rollovers/rollover_message_content';
import ProgressTableBox from 'components/tables/ProgressTableBox';
import ScoreTableBox from 'components/tables/ScoreTableBox';
import TimingTableBox from 'components/tables/TimingTableBox';
import IconButton from '@mui/material/IconButton';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Rollover from '../rollovers/rollover';
import { useTableKeyboardNavigation } from './useTableKeyboardNavigation';
import {
	NotebookChapterSummary,
	NotebookPageGroupSummary,
	NotebookPageSummary,
	NotebookSummary,
	TableRow,
} from '@soomo/lib/notebook/types';
import {
	getDueDateInfo,
	notebookLinkForPage,
	scorePercentage,
} from '@soomo/lib/notebook/utils';
import { formatDateTime, formatTimeSpent } from '@soomo/lib/formatters';
import styles from 'components/tables/styles';

export interface OverviewTableProps {
	notebookSummary: NotebookSummary;
	activeView?: 'progress' | 'score' | 'timing' | 'grades';
}

const HeaderForActiveView = {
	progress: 'Attempted',
	score: '% Correct',
	timing: 'Total Time',
};

const ChapterSummaryTooltipForActiveView = {
	progress: 'Number of questions you’ve completed in this chapter',
	score: 'Percentage of questions you answered correctly in this chapter',
	timing: 'Total time you spent in this chapter',
};

const OverviewTable: FC<OverviewTableProps> = ({
	notebookSummary,
	activeView = 'progress',
}) => {
	const [dataTableRef, handleDataTableKeydown] = useTableKeyboardNavigation();

	const showDueDateColumn = () => {
		const hasDueDates =
			notebookSummary.dueDates !== null && Object.keys(notebookSummary.dueDates).length > 0;
		return hasDueDates && activeView !== 'timing';
	};

	const renderCourseSummaryCell = () => {
		const { courseSummary } = notebookSummary;
		switch (activeView) {
			case 'progress':
				return `${courseSummary.questionsCompleted}/${courseSummary.questionsPossible}`;
			case 'score':
				return scorePercentage(courseSummary);
			case 'timing':
				return formatTimeSpent(courseSummary.totalTimeSpent);
		}
	};

	const renderChapterDueDateCell = (
		summary: NotebookChapterSummary | NotebookPageGroupSummary
	) => {
		if (!summary.dueAt) {
			return null;
		}

		const dueAt = DateTime.fromISO(summary.dueAt);
		const penaltyPeriodEndsAt = DateTime.fromISO(summary.penaltyPeriodEndsAt);
		const now = DateTime.local();

		let datetimeToDisplay = dueAt;
		if (dueAt <= now && penaltyPeriodEndsAt > now && summary.pastDuePenaltyFactor > 0) {
			datetimeToDisplay = penaltyPeriodEndsAt;
		}

		const formattedTime = formatDateTime(datetimeToDisplay, 'M/d/yy<br/>hh:mm a ZZZZ');
		return (
			<span
				className={cn('break-wrap', { italic: datetimeToDisplay === penaltyPeriodEndsAt })}
				dangerouslySetInnerHTML={{ __html: formattedTime }}
			/>
		);
	};

	const renderChapterSummaryCell = (
		summary: NotebookChapterSummary | NotebookPageGroupSummary
	) => {
		switch (activeView) {
			case 'progress':
				return `${summary.questionsCompleted}/${summary.questionsPossible}`;
			case 'score':
				if (summary.questionsScored > 0) {
					return scorePercentage(summary);
				}
				return null;
			case 'timing':
				return formatTimeSpent(summary.totalTimeSpent);
		}
	};

	const renderPageCell = (page: NotebookPageSummary) => {
		switch (activeView) {
			case 'progress':
				return <ProgressTableBox pageSummary={page} />;
			case 'score':
				return <ScoreTableBox pageSummary={page} />;
			case 'timing':
				return <TimingTableBox pageSummary={page} />;
		}
	};

	const renderHead = () => {
		const hasPageGroups = Object.keys(notebookSummary.pageGroupMap).length > 0;

		const activeViewLabel = HeaderForActiveView[activeView];
		const activeViewRollover = (
			<RolloverMessageContent>
				{ChapterSummaryTooltipForActiveView[activeView]}
			</RolloverMessageContent>
		);

		return (
			<thead>
				<tr>
					<th>{notebookSummary.chaptersLabel}</th>
					{showDueDateColumn() && (
						<th scope="column" style={{ minWidth: '120px' }}>
							Due Date
						</th>
					)}
					<th scope="column">
						<div className="info-cell">
							<span className="info-label">{activeViewLabel}</span>
							<Rollover trigger={'click'} content={activeViewRollover}>
								<IconButton
									aria-label={`see ${activeViewLabel} meaning`}
									classes={{ root: 'info-trigger' }}
								>
									<InfoOutlinedIcon fontSize={'inherit'} />
								</IconButton>
							</Rollover>
						</div>
					</th>
					{Array(notebookSummary.longestRowLength)
						.fill(0)
						.map((v, i) => (
							<th key={i} scope="column">
								{!hasPageGroups && 'p.'}
								{i + 1}
							</th>
						))}
				</tr>
			</thead>
		);
	};

	const renderChapterRow = (tableRow: TableRow) => {
		const chapter = tableRow.isPageGroup
			? notebookSummary.pageGroupMap[tableRow.id]
			: notebookSummary.chapterMap[tableRow.id];

		const rolloverChapterColumn = <RolloverChapterContent chapter={chapter} />;
		const rolloverDueDateColumn = (
			<RolloverMessageContent>{getDueDateInfo(chapter)}</RolloverMessageContent>
		);

		const pastDue = chapter.dueAt
			? DateTime.fromISO(chapter.dueAt) >= DateTime.local()
			: false;

		const chapterId = chapter.id;
		return (
			<tr key={chapterId}>
				<th
					id={chapterId}
					scope="row"
					onKeyDown={(event) => handleDataTableKeydown(event, chapterId)}
					className={cn({ bold: pastDue })}
				>
					<div className="info-cell">
						<span className="info-label">{chapter.rowLabel}</span>
						<Rollover trigger={'click'} content={rolloverChapterColumn} variant="pane">
							<IconButton
								aria-label={`show summary for the ${chapter.rowLabel}`}
								classes={{ root: 'info-trigger' }}
							>
								<InfoOutlinedIcon fontSize={'inherit'} />
							</IconButton>
						</Rollover>
					</div>
				</th>
				{showDueDateColumn() &&
					(chapter.dueAt ? (
						<td className={cn({ bold: !pastDue })}>
							<div className="info-cell">
								<span className="info-label">{renderChapterDueDateCell(chapter)}</span>
								<Rollover trigger={'click'} content={rolloverDueDateColumn}>
									<IconButton
										aria-label={`show due date info for the ${chapter.rowLabel}`}
										classes={{ root: 'info-trigger' }}
									>
										<InfoOutlinedIcon fontSize={'inherit'} />
									</IconButton>
								</Rollover>
							</div>
						</td>
					) : (
						<td />
					))}
				<td className={cn({ bold: showDueDateColumn() && !pastDue })}>
					{renderChapterSummaryCell(chapter)}
				</td>
				{Array(notebookSummary.longestRowLength)
					.fill(0)
					.map((v, i) => {
						if (i < chapter.pages.length) {
							const page = notebookSummary.pageMap[chapter.pages[i]];
							const cellContent = (
								<Link to={notebookLinkForPage(page.id)}>{renderPageCell(page)}</Link>
							);

							const pageCellId = `${chapterId}_${page.id}`;
							return (
								<td
									key={pageCellId}
									id={pageCellId}
									onKeyDown={(event) => handleDataTableKeydown(event, pageCellId)}
									className="page-column"
								>
									{cellContent}
								</td>
							);
						}
					})}
			</tr>
		);
	};

	const renderBody = () => {
		return <tbody>{notebookSummary.tableRows.map((c, i) => renderChapterRow(c))}</tbody>;
	};

	return (
		<div className={styles}>
			<table ref={dataTableRef}>
				{renderHead()}
				{renderBody()}
			</table>
		</div>
	);
};

export default OverviewTable;
