import React from 'react';
import styles from './styles';

const DashedLine = () => (
	<div className={styles}>
		<span />
		<span />
		<span />
	</div>
);

export default DashedLine;
