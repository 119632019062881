export const fadeIn = (timeMS: number, easing = 'cubic-bezier(0.23, 1, 0.32, 1)') => `
	animation: fadein ${timeMS}ms ${easing} forwards;
	@keyframes fadein {
		0% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}`;
