import React, { FC } from 'react';
import { css } from 'emotion';
import { supportEmail, supportPhone } from '@soomo/lib/properties/support';

interface ErrorMessageProps {
	title: string;
	showDefaultMessage?: boolean;
}

const errorMessageStyles = css`
	margin: 1rem;
	font-weight: lighter;
	margin: 1rem;
	font-family: Arial, Helvetica, sans-serif;

	h1 {
		font-weight: 300;
	}
	h2 {
		font-weight: lighter;
	}

	.error {
		font-style: italic;
		color: #c02027;
	}
`;

const ErrorMessage: FC<ErrorMessageProps> = ({
	title,
	showDefaultMessage = true,
	children,
}) => (
	<div className={errorMessageStyles}>
		<h1>{title || 'Something Went Wrong'}</h1>
		<hr />
		{showDefaultMessage && (
			<p>
				Please try refreshing the page. If the error persists, click the round button in the
				lower right to contact us. You can also call support at{' '}
				<a href={`tel:${supportPhone}`} target="_blank">
					{supportPhone}
				</a>
				, or email us at{' '}
				<a href={`mailto:${supportEmail}`} target="_blank">
					{supportEmail}
				</a>
			</p>
		)}
		<p className="error">{children}</p>
	</div>
);

export default ErrorMessage;
