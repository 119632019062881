import React, { FC } from 'react';
import { css } from 'emotion';

interface LayoutProps {
	onClick: () => void;
}

const styles = css`
	background-color: white;
	width: 100%;
	overflow-y: auto;
`;

const Layout: FC<LayoutProps> = ({ onClick, children }) => (
	<div id="layout" onClick={onClick} className={styles}>
		{children}
	</div>
);

export default Layout;
