import React, { FC, useEffect } from 'react';
import cn from 'classnames';
import OverviewTable, { OverviewTableProps } from '../../components/tables/overview_table';
import ProgressViewLegend from '../../components/legends/progress_view_legend';
import ScoreViewLegend from '../../components/legends/score_view_legend';
import TimingViewLegend from '../../components/legends/timing_view_legend';
import GradesTable from '../../components/tables/GradesTable';
import styles from './styles';
import { useAppDispatch, useAppSelector } from '../../store';
import { useQuery } from '../../hooks/useQuery';
import { history } from '../../navigation/Routing';
import { refreshData, setActivePage, setOverviewCategory } from '../../store/actions';

enum ActiveViewTitle {
	'progress' = 'My Progress',
	'score' = 'My Score',
	'timing' = 'My Active Time',
	'grades' = 'My Gradebook Points',
}

const OverviewScreen: FC = () => {
	const dispatch = useAppDispatch();

	const queryParams = useQuery();
	const queryActiveView = queryParams.get('view') as OverviewTableProps['activeView'];

	const activeView = queryActiveView || 'progress';
	const notebookSummary = useAppSelector((state) => state.notebookSummary);

	const openedPagesCount = useAppSelector(
		(state) => Object.keys(state.elementsActivity).length
	);

	/**
	 * Append ?view=progress search query if nothing was provided
	 */
	useEffect(() => {
		if (queryActiveView) return;
		history.replace({ search: `?view=${activeView}` });
	}, [queryActiveView, activeView]);

	useEffect(() => {
		if (!openedPagesCount) return;
		dispatch(refreshData.request());
	}, []);

	useEffect(() => {
		dispatch(setActivePage(null));
	}, []);

	useEffect(() => {
		dispatch(setOverviewCategory(activeView));
		document.title = ActiveViewTitle[activeView];
	}, [activeView]);

	return (
		<div className={cn('overview-screen', styles)}>
			<div className="scroll-container">
				{activeView === 'grades' ? (
					<GradesTable />
				) : (
					<OverviewTable notebookSummary={notebookSummary} activeView={activeView} />
				)}
			</div>
			<div className="legend">
				{activeView === 'progress' && <ProgressViewLegend />}
				{activeView === 'score' && <ScoreViewLegend />}
				{activeView === 'timing' && <TimingViewLegend />}
			</div>
		</div>
	);
};

export default OverviewScreen;
