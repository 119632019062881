import { css } from 'emotion';
import { colors } from 'styles/theme';

export default css`
	width: 15px;
	padding: 0;
	color: white;
	font-family: inherit;
	font-size: inherit;
	font-weight: inherit;
	overflow: hidden;

	&:disabled {
		color: ${colors.grey};
	}

	.nudge-arrow-icon {
		font-size: inherit;
	}
`;
