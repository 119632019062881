import { Manifest } from '@soomo/lib/types/WebtextManifest';

import { StudentNotebookAppState } from 'types';
import { RootState } from './index';

export const selectStudentFullname = (state: RootState) => {
	return state.user ? state.user.name : '';
};

export const elementsActivityForPage = (state: StudentNotebookAppState, page: string) =>
	state.elementsActivity[page] ? state.elementsActivity[page] : null;

const chapterLabel = (chapter, chapterNamesIncludeNumbering) => {
	const chapterNumber = parseInt(chapter.chapter_number, 10);
	let label = chapter.chapter_name;
	if (chapterNumber && !chapterNamesIncludeNumbering)
		label = `${chapterNumber}&nbsp;&nbsp;${label}`;
	return label;
};

const pageGroupLabel = (pageGroup) => {
	let label = pageGroup.name;
	if (pageGroup.page_group_number) label = `${pageGroup.page_group_number} ${label}`;
	return label;
};

export const pageLabel = (page) => {
	let label = page.page_name;
	if (page.page_nav_label) label = `<em>${page.page_nav_label}:</em> ${label}`;
	if (page.page_number) label = `${page.page_number} ${label}`;
	return label;
};

const pageItem = (page) => ({
	id: page.id,
	label: pageLabel(page),
});

const pageGroupItem = (pageGroup, pagesById) => ({
	id: pageGroup.id,
	label: pageGroupLabel(pageGroup),
	pages: pageGroup.page_ids.map((id) => pageItem(pagesById[id])),
});

export function tocOutlineSelector(manifest: Manifest) {
	const { toc, chapters, page_groups, pages } = manifest;
	const chaptersConfig = toc.config.chapters;

	const chapterNamesIncludeNumbering = chaptersConfig.chapter_names_include_numbering;
	return {
		chapters: toc.chapter_ids.map((chapterId) => {
			const chapter = chapters[chapterId];
			const chapterConfig = chaptersConfig.types[chapter.chapter_type] || {
				settings: {
					page_groups_enabled: false,
					toc_nav_item_collapsed_if_single_page: false,
				},
			};
			const chapterSettings = chapterConfig.settings;

			const chapterPageGroups = chapter.page_group_ids.map((id) =>
				pageGroupItem(page_groups[id], pages)
			);
			const chapterPages = chapter.page_ids.map((id) => pageItem(pages[id]));

			const chapterHasPageGroups = chapterSettings.page_groups_enabled;
			const collapsedIfSinglePage = chapterSettings.toc_nav_item_collapsed_if_single_page;

			const singleCollapsedPage =
				chapterPages && chapterPages.length === 1 && collapsedIfSinglePage;

			return {
				id: chapter.id,
				label: chapterLabel(chapter, chapterNamesIncludeNumbering),
				pageGroups: chapterPageGroups,
				pages: chapterPages,
				hasPageGroups: chapterHasPageGroups,
				singleCollapsedPage,
			};
		}),
	};
}
