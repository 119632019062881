import Rollbar from 'rollbar';
import {
	ROLLBAR_ACCESS_TOKEN,
	ROLLBAR_BRANCH,
	ROLLBAR_CODE_VERSION,
	ROLLBAR_ENABLED,
	ROLLBAR_ENV,
} from '../constants';

const rollbarConfig = {
	accessToken: ROLLBAR_ACCESS_TOKEN,
	captureUncaught: true,
	captureUnhandledRejections: true,
	payload: {
		environment: ROLLBAR_ENV,
		client: {
			javascript: {
				code_version: ROLLBAR_CODE_VERSION,
				source_map_enabled: true,
			},
		},
		server: {
			branch: ROLLBAR_BRANCH,
		},
	},
};

const rollbarInstance = ROLLBAR_ENABLED ? new Rollbar(rollbarConfig) : null;

type LoggingFunction = (...args: Rollbar.LogArgument[]) => Rollbar.LogResult | undefined;

export const logError: LoggingFunction = (...args) => {
	console.error(...args);
	return rollbarInstance?.error(...args);
};

export const logWarn: LoggingFunction = (...args) => {
	console.warn(...args);
	return rollbarInstance?.warn(...args);
};

export const logInfo: LoggingFunction = (...args) => {
	console.info(...args);
	return rollbarInstance?.info(...args);
};

export const logDebug: LoggingFunction = (...args) => {
	console.debug(...args);
	return rollbarInstance?.debug(...args);
};
