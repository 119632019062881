import { MutableRefObject, KeyboardEvent, useRef } from 'react';

const focusableSelector =
	'button:not([disabled]), [href], input:not([disabled]), select:not([disabled]), ' +
	'textarea:not([disabled]), [tabindex]:not([tabindex="-1"]):not([disabled]), ' +
	'details:not([disabled]), summary:not(:disabled)';

const getFocusableElementInclusively = (
	element: HTMLElement | undefined
): HTMLElement | null => {
	if (!element) return null;

	const isElementFocusable = element.matches(focusableSelector);
	if (isElementFocusable) {
		return element;
	}
	return element.querySelector(focusableSelector);
};

/**
 * Inspired with:
 * https://github.com/tannerlinsley/react-table/discussions/2752#discussioncomment-192558
 * https://github.com/tannerlinsley/react-table/discussions/2752#discussioncomment-372670
 */

type ArrowsHandler = (event: KeyboardEvent, elementId: string) => void;

export const useTableKeyboardNavigation = (): [
	tableRef: MutableRefObject<HTMLTableElement>,
	handleCellArrowKeydown: ArrowsHandler
] => {
	const tableRef = useRef<HTMLTableElement>(null);

	const focusElement = (element?: HTMLElement) => {
		const focusableElement = getFocusableElementInclusively(element);
		focusableElement?.focus();
	};

	const handleVerticalMovement = (event: KeyboardEvent, element: HTMLElement): void => {
		const rowElement = element.parentElement;
		if (!(rowElement instanceof HTMLTableRowElement)) {
			console.warn(
				"The method has been applied to the cell which parent isn't a row. " +
					'Cannot vertically navigate!'
			);
			return;
		}

		const rowElementIndex = [...rowElement.children].indexOf(element);

		const prevRowSibling = rowElement.previousElementSibling as HTMLElement;
		const nextRowSibling = rowElement.nextElementSibling as HTMLElement;

		const targetRow = event.key === 'ArrowUp' ? prevRowSibling : nextRowSibling;
		const targetRowCell = (targetRow?.children?.item(rowElementIndex) ||
			targetRow?.lastChild) as HTMLElement;

		focusElement(targetRowCell);
	};

	const handleHorizontalMovement = (event: KeyboardEvent, element: HTMLElement): void => {
		const prevCellSibling = element.previousElementSibling as HTMLElement;
		const nextCellSibling = element.nextElementSibling as HTMLElement;

		switch (event.key) {
			case 'ArrowLeft': {
				focusElement(prevCellSibling);
				break;
			}
			case 'ArrowRight': {
				focusElement(nextCellSibling);
				break;
			}
		}
	};

	/**
	 * Should be applied only to the direct children of the `tr` element
	 */
	const handleCellArrowKeydown = (event: KeyboardEvent, elementId: string): void => {
		event.stopPropagation();

		const table = tableRef.current;
		if (!table) return;

		const escapedElementId = CSS.escape(elementId);
		const element = table.querySelector(`#${escapedElementId}`) as HTMLElement;
		if (!element) return;

		switch (event.key) {
			case 'ArrowUp':
			case 'ArrowDown': {
				handleVerticalMovement(event, element);
				break;
			}
			case 'ArrowLeft':
			case 'ArrowRight': {
				handleHorizontalMovement(event, element);
				break;
			}
		}
	};

	return [tableRef, handleCellArrowKeydown];
};
