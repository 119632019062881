import React from 'react';
import cn from 'classnames';
import AnswerViewVoiceRecording from '@soomo/lib/components/pageElements/VoiceRecording/notebook/AnswerViewVoiceRecording';
import { VoiceRecordingElement } from '@soomo/lib/types/WebtextManifest';
import { Characters } from '@soomo/lib/utils';
import { formatTimestamp } from '@soomo/lib/formatters';
import { Course, VoiceRecordingAnswer } from 'types';
import NotebookInstructorFeedback from 'components/page_view/elements/NotebookInstructorFeedback';
import IconCorrectness from '../IconCorrectness';
import elementStyles from 'components/page_view/elements/element_styles';
import withLibsThemeProvider from 'hocs/withLibsThemeProvider';

interface VoiceRecordingProps {
	course: Course;
	element: VoiceRecordingElement;
	answer: VoiceRecordingAnswer;
	label?: string;
	elementActivity?: any;
}

const VoiceRecording = ({ course, element, answer, elementActivity }: VoiceRecordingProps) => {
	function renderHeader() {
		const lmsSubmissionAvailable = element.autosubmit && course.lms_supports_file_upload;

		let timeStamp = '';
		if (answer) {
			const postedOrAutoSubmitted = lmsSubmissionAvailable ? 'AUTO-SUBMITTED' : 'POSTED';
			const lastSaved = formatTimestamp(answer.saved_at);
			const postedAt = answer.posted_at
				? `| ${postedOrAutoSubmitted} ${formatTimestamp(answer.posted_at)}`
				: '';
			timeStamp = ` ${Characters.emdash} LAST SAVED ${lastSaved} ${postedAt}`;
		}

		return (
			<div className="element-activity-header">
				<span className="element-type">Voice Recording</span>
				{timeStamp}
				{elementActivity.past_due && ` ${Characters.emdash} PAST DUE`}
			</div>
		);
	}

	let recordingUrl = null;
	let transcript = null;
	if (answer && answer.data) {
		recordingUrl = answer.data.recordingUrl;
		transcript = answer.data.transcript;
	}

	return (
		<div className={cn('page-element voice-recording-question-element', elementStyles)}>
			<div className="element-content">
				{renderHeader()}
				<div className="element-results open-ended">
					{answer && answer.posted_at && <IconCorrectness />}
					{answer && !answer.posted_at && <span>Draft saved</span>}
				</div>
				<div className={cn({ completed: answer != null })}>
					{answer && answer.completed ? (
						<AnswerViewVoiceRecording
							recordingUrl={recordingUrl}
							transcript={transcript}
							containerClass="notebook-element"
						/>
					) : (
						<p className="no-answer">Not yet answered</p>
					)}
				</div>
				<NotebookInstructorFeedback comments={elementActivity.comments} />
			</div>
		</div>
	);
};

export default withLibsThemeProvider(VoiceRecording);
