import { FC, useEffect, useMemo } from 'react';
import { css } from 'emotion';
import { last } from 'lodash-es';
import { darken } from 'polished';

import { useAppDispatch, useAppSelector } from 'store/index';
import { fetchElementUnreadNotifications } from 'store/actions';
import { colors } from 'styles/theme';
import { textSkeletonMixin } from 'styles/mixins/skeleton';

interface Props {
	elementFamilyId: string;
}

const UnseenCommentRedirect: FC<Props> = (props) => {
	const { elementFamilyId } = props;

	const { notification, loading, error } = useUnreadCommentNotification(elementFamilyId);
	const pageLink = useMemo(() => {
		const text = 'webtext page';
		if (loading) {
			return <span className={textSkeletonMixin()}>{text}</span>;
		}
		if (!notification || error) {
			return text;
		}
		return (
			<a className={redirectLinkStyles} href={notification.url} target="_blank">
				webtext page
			</a>
		);
	}, [notification, loading, error]);

	return <>Visit the {pageLink} to see your instructor&#8217;s comment.</>;
};

function useUnreadCommentNotification(elementFamilyId: string) {
	const dispatch = useAppDispatch();

	const unreadNotifications = useAppSelector(
		(state) => state.elementsUnreadNotifications[elementFamilyId]
	);
	const notificationLoading = useAppSelector(
		(state) => state.async.elementsUnreadNotifications[elementFamilyId]
	);
	const notificationError = useAppSelector(
		(state) => state.errors.elementsUnreadNotifications[elementFamilyId]
	);

	useEffect(() => {
		if (!unreadNotifications && !notificationLoading && !notificationError) {
			dispatch(fetchElementUnreadNotifications.request(elementFamilyId));
		}
	}, [dispatch, elementFamilyId, unreadNotifications, notificationError, notificationLoading]);

	const unreadCommentNotifications = useMemo(
		() =>
			last(
				unreadNotifications?.filter(
					(n) =>
						n.notification_type === 'comment' || n.notification_type === 'unpost_and_comment'
				)
			),
		[unreadNotifications]
	);

	return {
		notification: unreadCommentNotifications,
		loading: notificationLoading,
		error: notificationError,
	};
}

const redirectLinkStyles = css`
	text-decoration: none;

	&,
	&:visited {
		color: ${colors.cobaltBlue};
		&:hover {
			color: ${darken(0.1, colors.cobaltBlue)};
		}
	}
`;

export default UnseenCommentRedirect;
