import React, { FC, useState } from 'react';
import cn from 'classnames';
import useTimeout from '../../../hooks/useTimeout';
import styles from './styles';

interface TextLoaderProps {
	content?: string;
	showAfterMS?: number;
	className?: string;
}

const TextLoader: FC<TextLoaderProps> = ({
	content = 'Loading...',
	showAfterMS,
	className,
}) => {
	const [showLoader, setShowLoader] = useState(!showAfterMS);
	useTimeout(() => setShowLoader(true), showAfterMS);

	if (!showLoader) return null;
	return <span className={cn(className, styles)}>{content}</span>;
};

export default TextLoader;
