import React, { FC } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useAppSelector } from '../../store';
import { PublicRouteProps } from '../PublicRoute';
import ProgressApp from '../../containers/ProgressApp';

type ProtectedApplicationRouteProps = PublicRouteProps;

const ProtectedApplicationRoute: FC<ProtectedApplicationRouteProps> = ({
	component: Component,
	title,
	...routeProps
}) => {
	const userId = useAppSelector((state) => state.userId);
	const accessToken = useAppSelector((state) => state.accessToken);
	if (!userId || !accessToken) {
		return <Redirect to="/error/token-missing" />;
	}
	return (
		<Route
			{...routeProps}
			render={(renderRouteProps) => {
				if (title) document.title = title;
				return (
					<ProgressApp {...renderRouteProps}>
						<Component {...renderRouteProps} />
					</ProgressApp>
				);
			}}
		/>
	);
};

export default ProtectedApplicationRoute;
