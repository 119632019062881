import React, { FC, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import useTimeout from '../../../hooks/useTimeout';
import usePrefersReducedMotion from '../../../hooks/usePrefersReducedMotion';
import TextLoader from '../TextLoader';
import styles from './styles';

interface CircularLoaderProps {
	showAfterMS?: number;
	textClassName?: string;
}

const CircularLoader: FC<CircularLoaderProps> = ({ showAfterMS = 0, textClassName }) => {
	const [showLoader, setShowLoader] = useState(!showAfterMS);
	useTimeout(() => setShowLoader(true), showAfterMS);

	const prefersReducedMotion = usePrefersReducedMotion();

	if (!showLoader) return null;
	return (
		<div className={styles}>
			{prefersReducedMotion ? (
				<TextLoader className={textClassName} />
			) : (
				<CircularProgress size={64} />
			)}
		</div>
	);
};

export default CircularLoader;
