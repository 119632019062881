import { FC, ElementType, KeyboardEvent } from 'react';
import Button, { ButtonProps } from '@mui/material/Button/Button';
import { ThemeProvider } from '@mui/material';
import { raisedButtonTheme } from './styles';

const RaisedButton = <C extends ElementType>(props: ButtonProps<C, { component?: C }>) => {
	const { children, ...muiButtonProps } = props;

	/**
	 * For a better UX and consistency with other apps,
	 * we should support both the Spacebar and Enter clicks on the <a /> links
	 */
	const handleLinkKeydown = (event: KeyboardEvent<HTMLButtonElement | HTMLAnchorElement>) => {
		const target = event.target as HTMLElement;
		const code = event.code;
		if (target.tagName !== 'A') return;
		if (code !== 'Space') return;

		target.click();
	};

	return (
		<ThemeProvider theme={raisedButtonTheme}>
			<Button variant="contained" onKeyDown={handleLinkKeydown} {...muiButtonProps}>
				{children}
			</Button>
		</ThemeProvider>
	);
};

export default RaisedButton;
