import Utils from './Utils';
import BuildTurabianOutput from './BuildTurabianOutput';
import BuildAPAOutput from './BuildAPAOutput';

const CITE_REGEXP = /#cite/i;
const MARKER_CITE_REGEXP = /#marker_cite/i;

class OutputContext {
	constructor(builder, selections) {
		this.builder = builder;
		this.selections = selections;

		const filteredSelections = this._filterSelections();

		switch (this.builder.citationStyle) {
			case 'turabian':
				Object.assign(this, BuildTurabianOutput(builder, filteredSelections));
				break;
			case 'apa':
				Object.assign(this, BuildAPAOutput(builder, filteredSelections));
				break;
		}

		this.valueOf = this.valueOf.bind(this);
		this.getFillInWithCitationOutput = this.getFillInWithCitationOutput.bind(this);

		this._outputHasFillInWithCitation = this._outputHasFillInWithCitation.bind(this);
	}

	userUpload(item) {
		const context = this.builder.elementContext;
		return (
			'https://' +
			context.host +
			'/api/courses/v1/webtext/builders/' +
			context.builderID +
			'/get_image?user_id=' +
			this.builder.userID +
			'&course_id=' +
			context.courseID +
			'&name=' +
			this.selections[item.dest] +
			'&scaled=true'
		);
	}

	// Public:
	valueOf(item) {
		let rawValue;
		if (Array.isArray(item.source)) {
			const rawValues = [];
			item.source.forEach((source) => {
				const value = this.selections[source];
				if (value) {
					if (rawValues.indexOf(value) === -1) {
						rawValues.push(value);
					}
				}
			}, this);

			switch (rawValues.length) {
				case 0:
					rawValue = item.default;
					break;
				case 1:
					rawValue = rawValues[0];
					break;
				case 2:
					rawValue = rawValues[0] + ' and ' + rawValues[1];
					break;
				default:
					rawValue = rawValues.slice(0, -1).join(', ') + ', and ' + rawValues[rawValues.length - 1];
			}
		} else {
			if (item.source != null && item.source.indexOf('.') > -1) {
				rawValue = this.builder.getReferencedValue(item.source);
			} else {
				let value = this.selections[item.source] || item.default;
				if (value) {
					if (value.hasOwnProperty('kind')) {
						if (value.kind === 'fill-in-with-citations') {
							const sourcePromptItem = this.builder.getSourceFromDestination(item);
							rawValue = this.getFillInWithCitationOutput({
								item,
								onScreen: true,
								useMarkerCitations: sourcePromptItem.redactorEnabled
							});
							rawValue = rawValue.replace(/\n/gi, '');

							if (!this.builder.isParagraphAllowed(item)) {
								rawValue = Utils.removeParagraphTags(rawValue);
							}
						} else {
							rawValue = null;
						}
					} else {
						if (!this.builder.isParagraphAllowed(item)) {
							value = Utils.removeParagraphTags(value);
						}
						rawValue = value
							.replace(/\n/gi, '')
							.replace(/&nbsp;/gm, ' ')
							.replace(/\s+/gm, ' ');
					}
				} else {
					rawValue = value;
				}
			}
		}

		if (rawValue && rawValue.length) {
			(item.options || []).forEach((option) => {
				switch (option) {
					case 'capitalize':
						rawValue = rawValue.charAt(0).toUpperCase() + rawValue.slice(1);
						break;
					case 'bold':
						rawValue = `<b>${rawValue}</b>`;
						break;
					case 'italic':
						rawValue = `<em>${rawValue}</em>`;
						break;
					case 'allcaps':
						rawValue = rawValue.toUpperCase();
						break;
				}
			});

			return Utils.scrubbedHtml(rawValue);
		} else {
			return null;
		}
	}

	// Public:
	fillInValue(item) {
		const value = this.selections[item.dest];

		if (value && value.hasOwnProperty('text')) {
			return value.text;
		} else {
			return value;
		}
	}

	getFillInWithCitationOutput({ item, onScreen, useMarkerCitations }) {
		const key = item.source ? item.source : null;
		const selection = this.selections[key];

		if (!selection) return '';
		if (!selection.text) return '';

		const citations = selection.citations;
		if (!citations) {
			const output = useMarkerCitations
				? selection.text
				: Utils.entityEscapeContent(selection.text);
			return onScreen ? output : `<w:r><w:t xml:space='preserve'>${output}</w:t></w:r>`;
		}

		let splits;
		if (useMarkerCitations) {
			const container = document.createElement('div');
			container.innerHTML = selection.text;
			const nodes = Array.from(container.querySelectorAll('*'));
			const markerNodes = nodes.filter(function (node) {
				return node.outerHTML.startsWith('<mark');
			});

			let selectionText = selection.text;
			markerNodes.forEach((markerNode) => {
				const marker = markerNode.outerHTML.replace(/&amp;/gim, '&');
				selectionText = selectionText.replace(marker, '#marker_cite');
			});

			splits = selectionText.split(MARKER_CITE_REGEXP);
		} else {
			splits = selection.text.split(CITE_REGEXP);
		}

		let output = '';
		splits.forEach(function (split, index) {
			const fragment = useMarkerCitations ? split : Utils.entityEscapeContent(split);

			const citation = citations[index];
			if (citation) {
				if (this.builder.citationStyle === 'apa') {
					if (citation.attributes) {
						const citationSources = this.builder.getReferencedValue(selection['source-list-ref']);
						const fragmentWithCitation =
							fragment + this._getAPAAttributeValue({ citation, citationSources });
						output += onScreen
							? fragmentWithCitation
							: `<w:r><w:t xml:space='preserve'>${fragmentWithCitation}</w:t></w:r>`;
					}
				} else if (this.builder.citationStyle === 'turabian') {
					if (this.turabianOutput[key]) {
						const referenceNumber = this.turabianOutput[key].citations[index];
						if (onScreen) {
							output += fragment + '<sup>' + referenceNumber + '</sup>';
						} else {
							output +=
								"<w:r><w:t xml:space='preserve'>" +
								fragment +
								"</w:t></w:r><w:r><w:rPr><w:rStyle w:val='FootnoteReference'/></w:rPr><w:footnoteReference w:id='" +
								referenceNumber +
								"'/></w:r>";
						}
					} else {
						// NOTE: This is for debug only. Should remove later.
						if (onScreen) {
							output += fragment;
						} else {
							output += "<w:r><w:t xml:space='preserve'>" + fragment + '</w:t></w:r>';
						}
					}
				} else {
					// SWS or other. NOT FULLY IMPLEMENTED.
					if (onScreen) {
						output += fragment;
					} else {
						output += "<w:r><w:t xml:space='preserve'>" + fragment + '</w:t></w:r>';
					}
				}
			} else {
				if (onScreen) {
					output += fragment;
				} else {
					output += `<w:r><w:t xml:space='preserve'>${fragment}</w:t></w:r>`;
				}
			}
		}, this);

		return output;
	}

	// Internal:
	_filterSelections() {
		const config = this.builder.config;
		const selections = this.selections;

		let keysToCite = config.docx && config.docx['citations-included-for'];

		if (!keysToCite) {
			if (config.output.text && config.output.text.some(this._outputHasFillInWithCitation, this)) {
				const fillIns = this.builder.findItemsForKind('fill-in-with-citations', config.output.text);
				keysToCite = fillIns.map((item) => item.dest || item.source);
			} else {
				keysToCite = [];
				config.prompts.forEach(function (prompt) {
					prompt.items.forEach(function (item) {
						if (item.kind === 'fill-in-with-citations') {
							if (selections[item.dest]) {
								keysToCite.push(item.dest);
							}
						}
					});
				});
			}
		}

		return keysToCite.reduce(function (filtered, key) {
			if (selections[key]) filtered[key] = selections[key];
			return filtered;
		}, {});
	}

	_getAPAAttributeValue({ citation, citationSources }) {
		let output = null;
		citationSources.forEach(function (citationSource) {
			if (citationSource['options-value'] === citation.key) {
				const styles = citationSource['supported-styles'].apa.styles;
				styles.forEach(function (style) {
					const apaKey = Object.keys(style)[0];
					if (apaKey === citation.attributes.style) {
						output || (output = style[apaKey]);
					}
				});
			}
		});
		output || (output = '');

		const pageNumber = citation.attributes['page-number'];
		if (pageNumber) {
			output = Utils.replacePagePlaceholder(output, pageNumber);
		}

		return Utils.escapeContent(output);
	}

	_outputHasFillInWithCitation(item) {
		if (item.kind === 'fill-in-with-citations') {
			return true;
		} else if (item.kind === 'para') {
			return item.items.some(this._outputHasFillInWithCitation, this);
		}
	}
}

export default OutputContext;
