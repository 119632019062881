import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from 'store';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';
import { Global } from '@emotion/react';
import Routing, { history } from './navigation/Routing';
import styles from './styles';

ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<StyledEngineProvider injectFirst>
				<Global styles={styles} />
				<Router history={history}>
					<Routing />
				</Router>
			</StyledEngineProvider>
		</Provider>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
