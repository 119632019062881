import { css } from 'emotion';
import { colors } from 'styles/theme';

export default css`
	.selection-button {
		width: 100%;
		height: 5em;
		font-size: 0.8em;
		font-weight: normal;
		color: white;
		text-align: left;
		user-select: none;
		background-color: ${colors.darkNavy1};

		&[data-active='true'] {
			background-color: ${colors.lavender};
		}

		&:hover {
			background-color: ${colors.darkNavy3};
		}
	}

	.selection-label {
		width: 100%;
		margin: auto 12px;
	}

	.help-message {
		margin: 20px 16px;
	}
`;
