export const fonts = {
	appFontFamily: 'Neue Helvetica eText W01',
	thinAppFontFamily: 'Neue Helvetica Georgian W82',
	headingFontFamily: 'Proxima Nova W01',
};

export const colors = {
	pleasantRed: '#c02027',
	red: '#ff0043',
	blue: '#5a80ff',
	cobaltBlue: '#3762f7',
	darkBlue: '#0f1836',
	veryDarkBlue: '#0b152b',
	lighterBlue: '#5aa0ff',
	veryLightBlue: '#f5f7ff',
	blueDisabled: '#9ac0ff',
	orange: '#ff8200',
	tocEntry: '#b3b3b1',
	grey: '#686868',
	darkGrey: '#18345e',
	evilGrey: '#666',
	darkNavy1: '#0b152b',
	darkNavy2: '#0f1a36',
	darkNavy3: '#14294d',
	lavender: '#5a80ff',
	mediumDarkLavender: '#4867cc',
	darkLavender: '#364d99',
	darkWhite: '#f1f1f1',
	offWhite: 'rgb(240, 240, 240)',

	appAccentColor: '#5a80ff',
};
