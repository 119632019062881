import { NotebookPageSummary } from '@soomo/lib/notebook/types';

export type PageCompletionType =
	| 'blank'
	| 'complete'
	| 'no-longer-completable'
	| 'partially-complete'
	| 'no-longer-attemptable'
	| 'not-graded'
	| 'not-attempted';

interface PageTypeArgs
	extends Pick<
		NotebookPageSummary,
		'pageOpened' | 'questionsCompleted' | 'questionsScored' | 'questionsPossible'
	> {
	noLongerCompletable: boolean;
}

export const getPageProgressType = ({
	pageOpened,
	noLongerCompletable,
	questionsCompleted,
	questionsPossible,
}: PageTypeArgs): Exclude<PageCompletionType, 'not-graded' | 'blank'> => {
	if (!pageOpened) return noLongerCompletable ? 'no-longer-attemptable' : 'not-attempted';
	if (questionsCompleted === questionsPossible) return 'complete';
	if (noLongerCompletable) return 'no-longer-completable';
	return 'partially-complete';
};

export const getPageScoreType = ({
	pageOpened,
	noLongerCompletable,
	scored,
	questionsScored,
	questionsCompleted,
	questionsPossible,
}: PageTypeArgs & { scored: number; }): PageCompletionType => {
	if (!questionsScored) return 'blank';
	if (!pageOpened) return noLongerCompletable ? 'no-longer-attemptable' : 'not-attempted';
	if (scored === 1 && questionsCompleted === questionsPossible) return 'complete';
	if (noLongerCompletable) return 'no-longer-completable';
	return 'partially-complete';
};
