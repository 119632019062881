import React, { FC } from 'react';
import cn from 'classnames';
import { Box } from '@mui/material';
import { formatTimestamp } from '@soomo/lib/formatters';

import { getGradePercentage } from '@soomo/lib/components/pageElements/GoReact';
import { GoReactElement } from '@soomo/lib/components/pageElements/GoReact/types';

import { GoReactAnswer } from 'types';
import elementStyles from '../element_styles';
import IconCorrectness from '../IconCorrectness';

interface Props {
	element: GoReactElement;
	answer: GoReactAnswer;
}

const GoReact: FC<Props> = (props) => {
	const { element, answer } = props;

	const postedAt = answer?.posted_at;

	const instructorGradingRequired = element.score_config.scoreOptions === 'instructorGrade';
	const gradePercent = getGradePercentage(element, answer?.data);

	return (
		<div className={cn('page-element go-react-element', elementStyles)}>
			<div className="element-content">
				<div className="element-activity-header">
					<span className="element-type">
						Video Recorder
						{postedAt && <> - RECORDING POSTED {formatTimestamp(postedAt)}</>}
					</span>
				</div>
				<h2 className="element-body" dangerouslySetInnerHTML={{ __html: element.title }} />
				<div className="element-results">
					{postedAt && (
						<div>
							<IconCorrectness hideAlternativeText />
							<Box mt="0.5rem">Added</Box>
						</div>
					)}
				</div>
				{instructorGradingRequired && postedAt && (
					<h2 className="element-body">
						Grade:{' '}
						<Box display="inline" fontWeight="normal">
							{gradePercent !== null
								? gradePercent.toLocaleString(undefined, { style: 'percent' })
								: 'Not Yet Graded'}
						</Box>
					</h2>
				)}
			</div>
		</div>
	);
};

export default GoReact;
