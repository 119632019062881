import { css } from '@emotion/core';
import { fonts } from 'styles/theme';
import emotionNormalize from 'emotion-normalize';

export default css`
	@import url('//fast.fonts.net/cssapi/b78142e0-ec20-49c2-bed2-9d732c8ce9db.css');
	${emotionNormalize};

	*,
	*:before,
	*:after {
		box-sizing: border-box;
	}

	html {
		overflow: hidden;
	}

	body {
		overflow: auto;
		font-family: ${fonts.appFontFamily};
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}

	html,
	body {
		height: 100%;
		margin: 0;
		padding: 0;
	}

	div {
		outline: none;

		&:focus {
			outline: none;
		}
	}
`;
