import { css } from 'emotion';

export const rowColors = {
	even: '#ffffff',
	odd: '#f3f3f3'
}

const styles = css`
	margin: 1rem;

	table {
		display: inline-block;
		border: none;
		border-collapse: collapse;
		background-color: white;
		font-size: 12px;
		max-width: 1280px;

		@media (max-width: 1024px) {
			font-size: 11px;
		}

		.bold {
			font-weight: bold;
		}

		.italic {
			font-style: italic;
		}

		.break-wrap {
			white-space: pre;
		}

		a:hover {
			text-decoration: none;
			text-underline: none;
		}

		tr {
			background-color: ${rowColors.even};

			:nth-of-type(odd) {
				background-color: ${rowColors.odd};
			}
		}

		th {
			text-align: center;
			white-space: nowrap;
			font-weight: normal;

			&[scope='row'] {
				font-weight: normal;

				&.bold {
					font-weight: bold;
				}
			}
		}

		td {
			border: thin solid #e6e6e6;
			border-bottom: thin solid #969696;
			border-top: thin solid #969696;
			text-align: center;

			&.no-page {
				background: #ffffff;
				border: none;
			}

			&.chapter-column {
				padding: 0.25rem;
				width: 72px;
			}

			&.summary-column {
				padding: 0.25rem;
				width: 72px;
			}

			&.page-column {
				transition: background-color 100ms linear;

				:hover {
					background-color: rgba(82, 136, 255, 0.08);
				}

				min-width: 49px;
				padding-left: 8px;
				padding-right: 8px;

				a {
					display: flex;
					align-items: center;
					justify-content: center;
					text-decoration: none;
				}

				&:last-of-type {
					border-right: thin solid #969696;
				}
			}
		}

		thead {
			tr {
				color: white;
				height: 45px;
				font-weight: normal;

				th {
					position: sticky;
					top: 0;
					height: inherit;
					padding: 0.25rem 16px;
					border-right: thin solid white;
					border-bottom: thin solid white;
					background-color: #496ad4;
					z-index: 2;

					.info-cell {
						margin-right: -13px;
					}

					&:first-of-type {
						z-index: 3;
						left: 0;
						box-shadow: inset -1px -1px 1px 0 #e6e6e6;
					}
				}
			}
		}

		tbody {
			tr {
				height: 55px;

				th {
					box-shadow: inset -1px -1px 1px 0 #e6e6e6;
					border-right: thin solid #e6e6e6;
					border-bottom: thin solid #969696;
					border-top: thin solid #969696;
					position: sticky;
					left: 0;
					height: inherit;
					padding: 0.3rem;
					z-index: 1;
					background-color: inherit;
				}

				td {
					height: inherit;
					padding: 0.25rem;
				}
			}
		}

		.label-column {
			background-color: lightblue;
			position: absolute;
			width: 2em;
			left: 0;
		}

		.info-cell {
			display: flex;
			align-items: center;
			justify-content: space-between;
			gap: 4px;
			height: 100%;

			.info-label {
				flex: 1;
			}

			.info-trigger {
				width: 22px;
				height: 22px;
				align-self: start;
				color: inherit;
				font-size: 18px;
				outline: 0; // MUI buttons already have a ripple effect
			}
		}
	}
`;

export default styles;
