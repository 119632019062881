import React, { ComponentType, ReactElement } from 'react';

import themes, { ThemeProvider } from '@soomo/lib/styles/themes';

function withLibsThemeProvider<P>(WrappedComponent: ComponentType<P>) {
	// Default to blue_velvet since this is not in-context and just in My Progress
	const blueVelvetTheme = themes['blue_velvet'];

	const ComponentWithTheme = (props: P): ReactElement => (
		<ThemeProvider theme={blueVelvetTheme}>
			<WrappedComponent {...props} />
		</ThemeProvider>
	);
	return ComponentWithTheme;
}

export default withLibsThemeProvider;
