import { FC, useEffect } from 'react';
import { useAppSelector, useAppDispatch } from '../../store';
import { setNavbarOpen } from '../../store/actions';
import QuickActionsSelector from './QuickActionsSelector';
import ScopeSelector from './ScopeSelector';
import { drawerStyles } from './styles';
import { useMediaBreakpoint } from '@soomo/lib/hooks';
import { Drawer } from '@mui/material';

const SelectorPanel: FC = () => {
	const dispatch = useAppDispatch();

	const navbarOpen = useAppSelector((state) => state.navbarOpen);

	const isMediumScreen = useMediaBreakpoint('medium', 'max-width');
	useEffect(() => void dispatch(setNavbarOpen(!isMediumScreen)), [dispatch, isMediumScreen]);

	return (
		<Drawer
			open={navbarOpen}
			variant={isMediumScreen ? 'temporary' : 'permanent'}
			hideBackdrop
			elevation={1}
			ModalProps={{ keepMounted: true }}
			classes={drawerStyles}
		>
			<ScopeSelector />
			<QuickActionsSelector />
		</Drawer>
	);
};

export default SelectorPanel;
