import React, { FC, useState } from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import usePrefersReducedMotion from '../../../hooks/usePrefersReducedMotion';
import useTimeout from '../../../hooks/useTimeout';
import TextLoader from '../TextLoader';

interface ProgressLoaderProps {
	showAfterMS?: number;
	className?: string;
	barClassName?: string;
	textClassName?: string;
}

const ProgressLoader: FC<ProgressLoaderProps> = ({
	showAfterMS,
	barClassName,
	textClassName,
	className,
}) => {
	const [showLoader, setShowLoader] = useState(!showAfterMS);
	useTimeout(() => setShowLoader(true), showAfterMS);

	const prefersReducedMotion = usePrefersReducedMotion();

	if (!showLoader) return null;
	return prefersReducedMotion ? (
		<TextLoader className={textClassName} />
	) : (
		<LinearProgress classes={{ bar: barClassName }} className={className} />
	);
};

export default ProgressLoader;
