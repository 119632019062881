export const API_HOST = process.env.REACT_APP_API_HOST;
export const IS_DEVELOPMENT = (process.env.NODE_ENV || 'development') === 'development';

export const ROLLBAR_ACCESS_TOKEN = process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN;
export const ROLLBAR_ENV = process.env.REACT_APP_ROLLBAR_ENV || 'development';
export const ROLLBAR_ENABLED = process.env.REACT_APP_ROLLBAR_ENABLED === 'true' || false;
export const ROLLBAR_CODE_VERSION = process.env.REACT_APP_COMMIT_REF;
export const ROLLBAR_BRANCH = process.env.REACT_APP_BRANCH;

export const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_ID;
export const FULLSTORY_ORG_ID = process.env.REACT_APP_FULLSTORY_ORG_ID;
