import { css } from 'emotion';
import { colors } from 'styles/theme';
import { headerHeightPx } from '../Header/styles';
import { DrawerClasses } from '@mui/material/Drawer/drawerClasses';

const selectorPanelWidth = 300;

export const selectorPanelWidthPx = `${selectorPanelWidth}px`;

export const drawerStyles: Partial<DrawerClasses> = {
	root: css`
		top: ${headerHeightPx};
	`,
	paper: css`
		width: ${selectorPanelWidthPx};
		overflow-x: hidden;
		background: ${colors.darkNavy1};
		top: ${headerHeightPx};
	`,
	docked: css`
		display: block;
		width: ${selectorPanelWidthPx};
	`,
};

export const filterBox = css`
	margin: 0 16px 20px;
	padding: 8px 20px 16px;
	vertical-align: middle;
	background-color: white;
	border: 2px solid ${colors.lavender};
	border-radius: 5px;

	.filter-description-label {
		margin-top: 12px;
		margin-bottom: 12px;
		color: ${colors.lavender};
		font-size: 12px;
		font-weight: bold;
	}

	.filter-description {
		color: ${colors.darkGrey};
		font-size: 11px;
	}
`;

export const popoverSelector = css`
	color: white;

	.selector-label-button {
		width: 100%;
		height: 36px;
		padding: 0 0 0 20px;
		border-radius: 0;
		background-color: ${colors.darkNavy3};
		font-size: inherit;

		&:hover {
			background-color: ${colors.darkNavy2};
		}
	}

	.selector-label-box {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		height: 100%;
	}

	.selector-label {
		padding-top: 1px;
		color: ${colors.lavender};
		font-size: 0.75em;
		font-weight: bold;
		text-transform: uppercase;
		user-select: none;
	}

	.selector-label-indicators {
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 100%;
	}

	.selector-label-arrow-box {
		padding-right: 3px;

		svg {
			vertical-align: middle;
		}
	}

	.selector-label-indicator {
		width: 12px;
		height: 100%;
		background-color: ${colors.darkNavy3};

		&[data-active='true'] {
			background-color: ${colors.lavender};
		}
	}

	.selection-label-box {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		height: 5em;
		font-size: 0.8em;
		text-align: left;
		user-select: none;
		background-color: ${colors.darkNavy1};
	}

	.selection-label-text {
		flex: 1;
		margin: auto 30px;
		text-transform: none;
		color: white;
		user-select: none;
		vertical-align: middle;
	}

	.nudge-arrows-box {
		display: flex;
		align-items: center;
		width: 50px;
		color: ${colors.lavender};
		font-size: 42px;
	}
`;

export const popoverMenu = css`
	min-width: 250px;
	overflow-y: auto;
	padding-bottom: 8px;
	color: ${colors.tocEntry};
	background-color: ${colors.darkNavy1};
	border-left-width: 1px;
	border-left-style: solid;
	border-left-color: ${colors.darkNavy3};
`;
