import React, { FC } from 'react';
import { NotebookChapterSummary, NotebookPageGroupSummary } from '@soomo/lib/notebook/types';
import { formatTimeSpent, formatTimestamp } from '@soomo/lib/formatters';
import { getDueDateInfo, getPenaltyPeriodInfo } from '@soomo/lib/notebook/utils';
import { HiClock } from 'react-icons/hi';

/**
 * A summary card that accepts either a NotebookChapterSummary or a NotebookPageGroupSummary
 * and renders the summary data for all pages in the group
 *
 * A chapter here is referring to either a chapter summary, or a summary of a page group within a chapter
 */
interface RolloverChapterContentProps {
	chapter: NotebookChapterSummary | NotebookPageGroupSummary;
}

const RolloverChapterContent: FC<RolloverChapterContentProps> = ({ chapter }) => {
	const {
		label,
		lastActivityAt,
		totalTimeSpent,
		questionsPossible,
		questionsCompleted,
		questionsScored,
		questionsCorrect,
		pagesOpened,
		pages,
	} = chapter;
	const dueDateInfo = getDueDateInfo(chapter);
	const penaltyPeriodInfo = getPenaltyPeriodInfo(chapter);

	return (
		<>
			<div className="header">
				<h1 dangerouslySetInnerHTML={{ __html: label }} />
			</div>
			{dueDateInfo && (
				<div className="due-date-and-penalty-info">
					<span>{dueDateInfo}</span>
					{penaltyPeriodInfo && (
						<span className="penalty-period-info">{penaltyPeriodInfo}</span>
					)}
				</div>
			)}
			<div className="content">
				<h2>Summary</h2>
				<p>
					<span className="label">Last Active:</span>
					<span>{lastActivityAt ? formatTimestamp(lastActivityAt) : 'Never'}</span>
				</p>
				<p>
					<span className="label">Activity:</span>
					<span>
						<HiClock className="clock" /> {formatTimeSpent(totalTimeSpent)}
					</span>
				</p>
				<p>
					{questionsPossible === 0 ? (
						<span className="label">No Questions</span>
					) : (
						<>
							<span className="label">Answered:</span>
							<span>
								{questionsCompleted}/{questionsPossible} (
								{Math.floor((questionsCompleted / questionsPossible) * 100)}%)
							</span>

							{questionsScored > 0 && (
								<>
									<span className="label not-first-label">Correct:</span>
									<span>
										{questionsCorrect}/{questionsScored} (
										{Math.floor((questionsCorrect / questionsScored) * 100)}
										%)
									</span>
								</>
							)}
						</>
					)}
				</p>
				<p>
					<span className="label">Visited Pages:</span>
					<span>{Math.floor((pagesOpened / pages.length) * 100)}%</span>
				</p>
			</div>
			<div className="footer">
				<p className="note">
					<HiClock className="clock" />
					<span>
						Total time spent working on pages: clicking, typing and scrolling.
						<br />
						Does not include time spent visiting third-party links
					</span>
				</p>
			</div>
		</>
	);
};

export default RolloverChapterContent;
