import React, { FC, useCallback, useEffect } from 'react';
import { history } from './Routing';
import { useAppDispatch, useAppSelector } from '../store';
import { setTocOpen } from '../store/actions';

const KeyboardNavigation: FC = () => {
	const dispatch = useAppDispatch();
	const tocOpen = useAppSelector((state) => state.tocOpen);

	const handleKeyDown = useCallback(
		(event: KeyboardEvent) => {
			const { repeat, code } = event;
			if (repeat) return;

			switch (code) {
				case 'Backquote': {
					dispatch(setTocOpen(!tocOpen));
					break;
				}
				case 'Escape': {
					dispatch(setTocOpen(false));
					break;
				}
				case 'Digit1': {
					history.push('overview?view=progress');
					break;
				}
				case 'Digit2': {
					history.push('overview?view=score');
					break;
				}
				case 'Digit3': {
					history.push('overview?view=timing');
					break;
				}
			}
		},
		[dispatch, tocOpen]
	);

	useEffect(() => {
		document.addEventListener('keydown', handleKeyDown);
		return () => document.removeEventListener('keydown', handleKeyDown);
	}, [handleKeyDown]);

	return <></>;
};

export default KeyboardNavigation;
