import { getType } from 'typesafe-actions';
import Polling from '@soomo/lib/utils/polling';
import {
	getAccessToken,
	getBackgroundRefreshData,
	getCurrentUserData,
	getElementsActivity,
	getElementUnreadNotifications,
	getInitialNotebookAsyncState,
	getNotebookSummary,
} from 'api';
import {
	fetchAccessToken,
	fetchCurrentUserData,
	fetchElementsActivity,
	fetchElementUnreadNotifications,
	fetchInitialNotebookAsyncState,
	fetchNotebookSummary,
	refreshData,
} from 'store/actions';
import { logError } from 'utils/errors';
import { RootState } from './index';

const POLLING_INTERVAL = 60000;
let polling: Polling = null;
let lastRefreshEtag = null;

async function handleFetchInitialNotebookAsyncState({ dispatch, getState, action }) {
	const state: RootState = getState();
	const { courseId } = state;
	try {
		const response = await getInitialNotebookAsyncState(courseId, action.payload);
		// The app first async request completed as expected.
		// So we can start polling for further updates
		polling = new Polling(
			() => {
				dispatch(refreshData.request());
			},
			POLLING_INTERVAL,
			100
		);
		polling.stopPollingIfElementNotInView = false;
		polling.ensurePollingIsActive();
		dispatch(fetchInitialNotebookAsyncState.success(response.data));
	} catch (e) {
		logError(e);
		dispatch(fetchInitialNotebookAsyncState.failure(e.toString()));
	}
}

async function handleFetchNotebookSummary({ dispatch, getState }) {
	const state: RootState = getState();
	const { courseId } = state;
	try {
		const response = await getNotebookSummary(courseId);
		dispatch(fetchNotebookSummary.success(response.data));
	} catch (e) {
		logError(e);
		dispatch(fetchNotebookSummary.failure(e.toString()));
	}
}

async function handleFetchElementsActivity({ dispatch, getState, action }) {
	const state: RootState = getState();
	const { courseId } = state;
	const pageId = action.payload;
	try {
		const response = await getElementsActivity(courseId, pageId);
		// Catch weird race condition if api intercepts a 401 and redirects
		if (response && response.data) {
			dispatch(fetchElementsActivity.success(response.data));
		}
	} catch (e) {
		logError(e);
		dispatch(
			fetchElementsActivity.failure({
				pageId,
				error: e.toString(),
			})
		);
	}
}

async function handleFetchRefreshData({ dispatch, getState }) {
	const state: RootState = getState();
	const { courseId } = state;
	try {
		const pages = Object.keys(state.elementsActivity);
		const response = await getBackgroundRefreshData(courseId, pages);
		const etag = response?.headers?.etag;
		if (etag && etag !== lastRefreshEtag) {
			lastRefreshEtag = etag;
			// Only update data if something's changed
			dispatch(refreshData.success(response.data));
		} else {
			dispatch(refreshData.success({} as any));
		}
	} catch (e) {
		logError(e);
		dispatch(refreshData.failure(e.toString()));
	}
}

async function handleFetchAccessToken({ dispatch, action }) {
	try {
		const { oneTimeToken } = action.payload;
		const response = await getAccessToken(oneTimeToken);

		const accessToken = response.data;
		localStorage.setItem('accessToken', accessToken);

		dispatch(fetchAccessToken.success(accessToken));
	} catch (e) {
		logError(e);
		dispatch(fetchAccessToken.failure(e.toString()));
	}
}

async function handleFetchCurrentUserData({ dispatch }) {
	try {
		const response = await getCurrentUserData();
		dispatch(fetchCurrentUserData.success(response.data.user));
	} catch (e) {
		logError(e);
		dispatch(fetchCurrentUserData.failure(e.toString()));
	}
}

async function handleFetchElementUnreadNotifications({ dispatch, getState, action }) {
	const state: RootState = getState();
	const { courseId } = state;
	const elementFamilyId = action.payload;
	try {
		const response = await getElementUnreadNotifications(courseId, elementFamilyId);
		dispatch(fetchElementUnreadNotifications.success(response.data));
	} catch (e) {
		logError(e);
		dispatch(
			fetchElementUnreadNotifications.failure({ elementFamilyId, error: e.toString() })
		);
	}
}

export default [
	{
		action: getType(fetchInitialNotebookAsyncState.request),
		effect: handleFetchInitialNotebookAsyncState,
	},

	// IS IT EVEN INVOKED?
	{
		action: getType(fetchNotebookSummary.request),
		effect: handleFetchNotebookSummary,
	},

	{
		action: getType(fetchElementsActivity.request),
		effect: handleFetchElementsActivity,
	},
	{
		action: getType(refreshData.request),
		effect: handleFetchRefreshData,
	},
	{ action: getType(fetchAccessToken.request), effect: handleFetchAccessToken },
	{ action: getType(fetchCurrentUserData.request), effect: handleFetchCurrentUserData },
	{
		action: getType(fetchElementUnreadNotifications.request),
		effect: handleFetchElementUnreadNotifications,
	},
];
