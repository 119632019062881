import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect, ConnectedProps } from 'react-redux';
import cn from 'classnames';

import { Manifest, WritingTemplateElement } from '@soomo/lib/types/WebtextManifest';
import ReadOnlySpreadsheet from '@soomo/lib/components/ReadOnlySpreadsheet';
import { ActivityHistory, VersionHistoryItem } from '@soomo/lib/notebook/components';
import Charts from '@soomo/lib/components/WritingTemplate/Charts';
import {
	isDecisionVersion,
	UserDecisions,
	WebtextBuilderData,
} from '@soomo/lib/notebook/types';
import { Characters } from '@soomo/lib/utils';
import { formatTimestamp } from '@soomo/lib/formatters';

import elementStyles from 'components/page_view/elements/element_styles';
import NotebookInstructorFeedback from 'components/page_view/elements/NotebookInstructorFeedback';
import Outline from 'components/page_view/elements/Outline';
import { RootState } from 'store/index';
import { fetchAccessToken } from 'store/actions';
import { API_HOST } from 'constants/index';
import withLibsThemeProvider from 'hocs/withLibsThemeProvider';

import IconCorrectness from '../IconCorrectness';
import styles from './styles';
import UnseenCommentRedirect from '../UnseenCommentRedirect';

import { WebtextBuilder } from './models';
import { OutputContext } from './transforms';

interface Props extends WritingTemplateReduxProps {
	elementActivity?: any;
	element: WritingTemplateElement;
	manifest: Manifest;
	userDecisions: any;
	webtextBuilderData: WebtextBuilderData;
}

class WritingTemplate extends Component<Props> {
	renderHeader() {
		const { last_saved_at: lastSavedAt, last_autosubmit_at: lastAutosubmitAt } =
			this.props.webtextBuilderData;

		let timestamp = '';
		if (lastSavedAt) {
			timestamp =
				` ${Characters.emdash} LAST SAVED ${formatTimestamp(lastSavedAt)}` +
				(lastAutosubmitAt ? ` | AUTO-SUBMITTED ${formatTimestamp(lastAutosubmitAt)}` : '');
		}

		return (
			<div className="element-activity-header">
				<span className="element-type">Writing Template</span>
				{timestamp}
			</div>
		);
	}

	renderTablePrompt = (prompt, item, output) => {
		const numberOfColumns = Math.max.apply(
			null,
			item.rows.map((row) => row.length)
		);
		const columnWidths = item['column-widths'] || [];
		const unconfiguredWidths = numberOfColumns - columnWidths.length;

		if (unconfiguredWidths) {
			const configuredWidth = columnWidths
				.map((w) => parseInt(w.replace('%', '')))
				.reduce((sum, w) => sum + w, 0);
			const defaultWidth = (100 - configuredWidth) / unconfiguredWidths;
			for (let i = 0; i < unconfiguredWidths; i++) {
				columnWidths.push(`${defaultWidth}%`);
			}
		}

		const cols = columnWidths.map((w, index) => <col key={index} width={w} />);
		const rows = item.rows.map((row, rowIndex) => {
			const cells = row.map((cell, cellIndex) => {
				let value;
				switch (cell.kind) {
					case 'text':
						value = cell.value;
						break;
					case 'fill-in':
						value = output.selections[cell.dest];
						break;
					case 'selector':
						value = output.selections[cell.dest];
						break;
					default:
				}

				const style: any = {};
				if (cell.alignment != null) style.textAlign = cell.alignment;
				if (cell['vertical-alignment'] != null)
					style.verticalAlign = cell['vertical-alignment'];

				return (
					<td
						key={cellIndex}
						className={cn('block_prompt_item', {
							user_input_prompt_item: cell.kind !== 'text',
							blank_user_input_prompt_item: cell.kind === 'fill-in' && !value,
						})}
						style={style}
						colSpan={cell.colspan}
						rowSpan={cell.rowspan}
						dangerouslySetInnerHTML={{ __html: value }}
					/>
				);
			});
			return <tr key={rowIndex}>{cells}</tr>;
		});

		return (
			<table>
				<colgroup>{cols}</colgroup>
				<tbody>{rows}</tbody>
			</table>
		);
	};

	getImageUrl = ({ builderId, host, name, userId, courseId, jwtToken, scaled = true }) => {
		const path = `/api/courses/v1/webtext/builders/${builderId}/get_image`;
		const imageUrl = new URL(path, host);
		const imageUrlParams = new URLSearchParams({
			user_id: userId,
			course_id: courseId,
			name,
			jwt: jwtToken,
			scaled: String(scaled),
		});
		imageUrl.search = imageUrlParams.toString();

		return imageUrl.toString();
	};

	getContextImageUrl = ({
		outputContext,
		userId,
		courseId,
		host,
		image,
		jwtToken,
		scaled = false,
	}) => {
		const { selections } = outputContext;

		const filename = selections[image.dest];
		if (!filename) {
			return null;
		}

		const [builderId, name] = filename.split('/');

		return this.getImageUrl({
			builderId,
			host,
			name,
			userId: userId,
			courseId: courseId,
			jwtToken,
			scaled,
		});
	};

	renderPrompt = (prompt, item, nextItem, output) => {
		if (item.kind === 'table') {
			return this.renderTablePrompt(prompt, item, output);
		}

		let inline = true;
		let value = null;
		let blank = false;
		let notRendered = false;
		let userInput = true;

		let gap = null;
		let list = null;
		let spreadsheet = null;
		let chart = null;
		let outline = null;

		switch (item.kind) {
			case 'text':
				value = item.value;
				if (nextItem?.kind === 'text') {
					value = value + '<br>';
				}
				userInput = false;
				break;
			case 'gap':
				gap = <div style={{ margin: `${item.units}em 0` }} />;
				break;
			case 'value-of':
				value = output.valueOf(item);
				userInput = false;
				if (prompt.kind === 'item') inline = false; // outline item
				break;
			case 'fill-in':
			case 'fill-in-with-citations':
				value = output.fillInValue(item);
				if (prompt.presentation !== 'inline') inline = false;
				break;
			case 'paste-in':
				value = output.selections[item.dest];
				if (prompt.presentation !== 'inline') inline = false;
				break;
			case 'selector':
				value = output.selections[item.dest];
				break;
			case 'item': // outline item
				list = item.elements.map((listItem, idx, listItems) => {
					return (
						<div key={idx}>
							{this.renderPrompt(item, listItem, listItems[idx + 1], output)}
						</div>
					);
				});
				break;
			case 'list': // outline list
				list = (
					<ol>
						{item.items.map((listItem, idx, listItems) => (
							<li key={idx}>
								{this.renderPrompt(item, listItem, listItems[idx + 1], output)}
							</li>
						))}
					</ol>
				);
				break;
			case 'para': // "text" output, paragraph
				list = (
					<p>
						{item.items.map((paraItem, idx, paraItems) => (
							<span key={idx}>
								{this.renderPrompt(item, paraItem, paraItems[idx + 1], output)}
							</span>
						))}
					</p>
				);
				break;
			case 'map':
				notRendered = true;
				break;
			case 'spreadsheet': {
				const isResponded = !!output?.selections[item?.dest];
				if (isResponded) {
					spreadsheet = <ReadOnlySpreadsheet inputs={output.selections} item={item} />;
				} else {
					inline = false;
				}
				break;
			}
			case 'chart': {
				const response = output?.selections[item?.dest];
				if (response) {
					chart = <Charts value={output?.selections?.[item.dest]} isOutput={true} />;
				} else {
					inline = false;
				}
				break;
			}
			case 'outline': {
				const dest = item?.dest;
				const response = output?.selections[dest];
				if (response) {
					const { hierarchy } = response;
					outline = (
						<Outline
							builderFamilyId={this.props.element.family_id}
							dest={dest}
							config={{ hierarchy }}
							value={response}
						/>
					);
				} else {
					inline = false;
				}
				break;
			}
			case 'image': {
				const url = this.getContextImageUrl({
					outputContext: output,
					image: item,
					userId: this.props.userId,
					courseId: this.props.courseId,
					host: API_HOST,
					jwtToken: this.props.accessToken,
					scaled: true,
				});
				if (!url) {
					break;
				}

				const filename = output.selections[item.dest];
				if (filename?.endsWith('.pdf')) {
					const downloadUrl = this.getContextImageUrl({
						outputContext: output,
						image: item,
						userId: this.props.userId,
						courseId: this.props.courseId,
						host: API_HOST,
						jwtToken: this.props.accessToken,
						scaled: false,
					});
					list = (
						<a href={downloadUrl} target="_blank" rel="noopener noreferrer">
							<img className="UploadedFile" src={url} alt="" />
						</a>
					);
				} else {
					list = <img className="UploadedFile" src={url} alt="" />;
				}
				userInput = false;
				break;
			}
			default:
				value = JSON.stringify(item);
				userInput = false;
		}

		if (notRendered) return null;

		if (gap) return gap;
		if (list) return list;
		if (spreadsheet) return spreadsheet;
		if (chart) return chart;
		if (outline) return outline;

		if (!value) {
			value = '&nbsp;';
			blank = true;
		}

		if (inline) {
			return (
				<span
					className={cn('inline_prompt_item', {
						user_input_prompt_item: userInput,
						blank_user_input_prompt_item: blank,
					})}
					dangerouslySetInnerHTML={{ __html: value }}
				/>
			);
		}
		return (
			<div
				className={cn('block_prompt_item', {
					user_input_prompt_item: userInput,
					blank_user_input_prompt_item: blank,
				})}
				dangerouslySetInnerHTML={{ __html: value }}
			/>
		);
	};

	renderPrompts = (
		argsWebtextBuilderData?: WebtextBuilderData,
		argsUserDecisions?: UserDecisions
	) => {
		const {
			manifest,
			element: { family_id: builderFamilyId },
			webtextBuilderData: propsWebtextBuilderData,
			userDecisions: propsUserDecisions,
			courseId,
			userId,
		} = this.props;

		const webtextBuilderData = argsWebtextBuilderData || propsWebtextBuilderData;
		const userDecisions = argsUserDecisions || propsUserDecisions;

		const builder = new WebtextBuilder({
			config: getBuilderConfig(this.props.element, this.props.userDecisions),
			courseData: manifest.toc.config.course_data || {},
			courseDecisions: manifest.toc.config.course_decisions || {},
			userDecisions,
			userID: userId,
			dependentBuilderPrompts: webtextBuilderData.dependent_builder_prompts,
			elementContext: {
				host: API_HOST,
				builderID: builderFamilyId,
				courseID: courseId
			}
		});

		let selections = {};
		if (typeof webtextBuilderData.dests === 'object' && webtextBuilderData.dests !== null)
			selections = webtextBuilderData.dests._draft || webtextBuilderData.dests;
		Object.keys(webtextBuilderData.sources || {}).forEach((key) => {
			if (key[0] !== '_') selections[key] = webtextBuilderData.sources[key];
		});

		const output = new OutputContext(builder, selections);
		if (builder.config.prompts.length) {
			return builder.config.prompts.map((prompt, index) => (
				<div key={index}>
					{prompt.items.map((item, index, items) => (
						<span key={index}>
							{this.renderPrompt(prompt, item, items[index + 1], output)}
						</span>
					))}
				</div>
			));
		} else if (builder.config.output.outline) {
			return builder.config.output.outline.map((item, index, items) => (
				<div key={index}>{this.renderPrompt(item, item, items[index + 1], output)}</div>
			));
		} else {
			throw new Error('Cannot find prompts or outline output.');
		}
	};

	renderActivityHistory = () => {
		const { element, userId, webtextBuilderData } = this.props;
		const { history } = webtextBuilderData;

		const answerVersionsHistory = history?.filter((v) => v.event !== 'saved');
		const postedEventCount =
			answerVersionsHistory?.filter((v) => v.event === 'posted')?.length || 0;

		if (!answerVersionsHistory?.length) return null;

		/**
		 * Tracks the user's decisions set that should be used for rendering the history items
		 * Gets updated after the historical decision reset event is encountered
		 */
		let userDecisions = this.props.userDecisions;
		return (
			<ActivityHistory attemptCount={postedEventCount}>
				{answerVersionsHistory.map((item, index) => {
					const historyWebtextBuilderData =
						'body' in item ? { ...webtextBuilderData, dests: item.body } : undefined;
					userDecisions = isDecisionVersion(item) ? item.decisions : userDecisions;
					return (
						<VersionHistoryItem
							key={`${item.event}-${index}`}
							version={item}
							studentId={userId}
							expandedAnswerContent={this.renderPrompts(
								historyWebtextBuilderData,
								userDecisions
							)}
							showSeenCommentStatus={false}
							concealedCommentContent={
								<UnseenCommentRedirect elementFamilyId={element.family_id} />
							}
						/>
					);
				})}
			</ActivityHistory>
		);
	};

	render() {
		const { element, elementActivity } = this.props;
		const {
			dests,
			locked,
			locked_message,
			last_autosubmit_at,
			lms_file_upload_support: autosubmittable,
		} = this.props.webtextBuilderData;

		const isComplete = dests && !dests._draft;
		const isDraft = dests && dests._draft;

		return (
			<div className={cn('page-element writing-template-element', elementStyles, styles)}>
				<div className="element-content">
					{this.renderHeader()}
					<h2
						className="element-body"
						dangerouslySetInnerHTML={{ __html: element.config.title || null }}
					/>
					<div className="element-results">
						{isComplete && !autosubmittable && (
							<div>
								<IconCorrectness hideAlternativeText />
								<div className="draft-added">Added</div>
							</div>
						)}

						{isComplete && autosubmittable && last_autosubmit_at && (
							<div>
								<IconCorrectness hideAlternativeText />
								<div className="draft-added">Auto-Submitted</div>
							</div>
						)}

						{isComplete && autosubmittable && !last_autosubmit_at && (
							<span>Added (Not yet submitted)</span>
						)}

						{isDraft && <span>Draft saved</span>}
					</div>
					<div className="prompts">{this.renderPrompts()}</div>
					{locked && (
						<div
							className="locked-message"
							dangerouslySetInnerHTML={{ __html: locked_message }}
						/>
					)}
					<NotebookInstructorFeedback comments={elementActivity.comments} />
					{this.renderActivityHistory()}
				</div>
			</div>
		);
	}
}

export const getBuilderConfig = (writingTemplate: WritingTemplateElement, userDecisions) => {
	if (!Array.isArray(writingTemplate.depends_on) || !writingTemplate.depends_on.length) {
		return writingTemplate.config;
	}

	const decisionName = writingTemplate.depends_on[0];
	const userDecision = userDecisions[decisionName];
	return userDecision ? JSON.parse(writingTemplate.dictionary.config[userDecision]) : {};
};

const mapStateToProps = (state: RootState) => ({
	accessToken: state.accessToken,
	userId: state.userId,
	courseId: state.courseId,
});

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			fetchAccessTokenRequest: fetchAccessToken.request,
		},
		dispatch
	);

const connector = connect(mapStateToProps, mapDispatchToProps);

type WritingTemplateReduxProps = ConnectedProps<typeof connector>;

export default connector(withLibsThemeProvider(WritingTemplate));
