import React, { FC } from 'react';
import { createBrowserHistory } from 'history';
import { Redirect, Route, Switch } from 'react-router-dom';
import PublicRoute from './PublicRoute';
import ProtectedApplicationRoute from './ProtectedApplicationRoute';
import JwtAuthGateway from '../pages/JwtAuthGateway';
import WelcomeScreen from '../pages/Welcome';
import OverviewScreen from '../pages/Overview';
import PageViewScreen from '../pages/PageView';
import Error from '../pages/Error';

const Routing: FC = () => (
	<Switch>
		<PublicRoute
			path="/courses/:courseId(\d+)/authentication"
			title={`Looking for the course`}
			component={JwtAuthGateway}
		/>
		<ProtectedApplicationRoute
			path="/courses/:courseId(\d+)/welcome"
			title={`Welcome to My Progress`}
			component={WelcomeScreen}
		/>
		<ProtectedApplicationRoute
			path="/courses/:courseId(\d+)/overview"
			component={OverviewScreen}
		/>
		<ProtectedApplicationRoute
			path="/courses/:courseId(\d+)/page_view"
			component={PageViewScreen}
		/>

		{/* `render` props is required to redirect both the `/courses/:courseId` & `/courses/:courseId/` urls to the welcome page */}
		<Route
			path="/courses/:courseId(\d+)"
			render={({ match }) => <Redirect to={`${match.url}/welcome`} />}
		/>

		<PublicRoute exact path="/error/:errorType" title={`Error happened`} component={Error} />
		<Route path="/*">
			<Redirect to="/error/course-id-missing" />
		</Route>
	</Switch>
);

export const history = createBrowserHistory();

export default Routing;
