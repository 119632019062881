import React from 'react';
import { schemeCategory10 } from 'd3-scale-chromatic';
import { arc, pie } from 'd3-shape';
import { css } from 'emotion';
import { darken } from 'polished';
import cn from 'classnames';
import { PollQuestionAnswer } from '@soomo/lib/notebook/types';
import { PollQuestionElement } from '@soomo/lib/types/WebtextManifest';

interface Props {
	element: PollQuestionElement;
	answer: PollQuestionAnswer;
}

const PollResults = (props: Props) => {
	const { element, answer } = props;
	const graphData = answer.graph;
	const outerRadius = 80;
	const colors = schemeCategory10;
	const opacity = 0.7;
	const strokeWidth = 0.5;
	const d3Arc = arc();
	if (!answer) {
		return <p>No answer</p>;
	}
	let totalResponseCount = 0;
	const data = graphData.map((d) => {
		totalResponseCount += d.data;
		return d.data;
	});

	const arcs = pie()(data).map((arcData, idx) => (
		<path
			key={idx}
			d={d3Arc({ ...arcData, innerRadius: 0, outerRadius })}
			fill={colors[idx % 10]}
			stroke="white"
			strokeWidth={strokeWidth}
			fillOpacity={opacity}
			transform={`translate(${outerRadius + 1},${outerRadius + 1})`}
		/>
	));

	const labelFontSize = 13;
	const labels = pie()(data).map((arcData, idx) => {
		const pct = Math.round(((arcData.data as number) / totalResponseCount) * 100.0);
		if (pct < 5) return null;
		const labelText = `${pct}%`;
		const pos = d3Arc.centroid({
			startAngle: arcData.startAngle,
			endAngle: arcData.endAngle,
			outerRadius,
			innerRadius: outerRadius / 2.2,
		});
		const x = pos[0] + outerRadius + 1;
		const y = pos[1] + outerRadius + 1 + labelFontSize / 3;

		return (
			<text key={idx} x={x} y={y} textAnchor="middle" fontSize={labelFontSize} fill="#333">
				{labelText}
			</text>
		);
	});

	const width = outerRadius * 2 + 2;
	const height = width;
	return (
		<div className={cn(styles, 'poll-results', 'question-answer')}>
			<svg width={width} height={height}>
				{arcs}
				{labels}
			</svg>
			<div className="legend">
				{graphData.map((e, i) => (
					<div key={i} className="legend-row">
						<span className="label">
							<div
								className="color"
								style={{
									backgroundColor: colors[i % 10],
									borderColor: darken(0.05, colors[i % 10]),
								}}
							/>
							<span>{e.label}</span>
						</span>
						<span>{e.data}</span>
					</div>
				))}
				<hr />
				<div className="total">Total responses: {totalResponseCount}</div>
			</div>
		</div>
	);
};

const styles = css`
	display: inline-flex;
	svg {
		flex-shrink: 0;
	}

	.legend {
		max-width: 300px;
		min-width: 256px;
		margin-left: 72px;
		hr {
			border: thin solid black;
		}

		.legend-row {
			width: 100%;
			display: flex;
			justify-content: space-between;
			font-size: 0.875rem;
			.label {
				display: flex;
				margin-right: 32px;
				.color {
					margin-top: 4px;
					margin-right: 8px;
					border-style: solid;
					border-width: 2px;
					flex-shrink: 0;
					width: 14px;
					height: 14px;
				}
			}
		}
		.total {
			text-align: right;
		}
	}
`;

export default PollResults;
