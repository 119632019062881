import React, { FC } from 'react';
import IconButton from '@mui/material/IconButton';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import styles from './styles';

interface NudgeArrowProps {
	direction: string;
	onClick?: () => void;
	disabled?: boolean;
}

const NudgeArrow: FC<NudgeArrowProps> = ({ direction, onClick, disabled }) => (
	<IconButton
		key={direction}
		disabled={disabled}
		size={'large'}
		classes={{ root: styles }}
		onClick={onClick}
		aria-label={`go ${direction}`}
	>
		{direction === 'forward' ? (
			<ArrowRightIcon classes={{ root: 'nudge-arrow-icon' }} />
		) : (
			<ArrowLeftIcon classes={{ root: 'nudge-arrow-icon' }} />
		)}
	</IconButton>
);

export default NudgeArrow;
