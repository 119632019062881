import { css } from 'emotion';

const styles = css`
	padding: 1rem;
	min-height: 72px;
	width: 100%;
	display: inline-flex;
	align-items: center;
	justify-content: flex-start;
	font-size: 12px;
	flex-wrap: wrap;
	> span {
		margin-bottom: 0.5rem;
		display: flex;
		align-items: center;
		margin-right: 2rem;
		> span {
			.no-wrap {
				white-space: nowrap;
			}
			padding-left: 0.65rem;
			font-size: 0.65rem;
		}
	}
`;

export default styles;
