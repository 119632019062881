import React, { FC, useMemo } from 'react';

import { Link, useRouteMatch } from 'react-router-dom';
import cn from 'classnames';

import { useMediaBreakpoint } from '@soomo/lib/hooks';

import { setNavbarOpen } from 'store/actions';
import { useAppDispatch, useAppSelector } from 'store/index';
import { RaisedButton } from 'components';
import { complete, incomplete } from 'components/legends/progress_view_legend';
import { lowScore } from 'components/legends/score_view_legend';
import ProgressTableBox from 'components/tables/ProgressTableBox';
import ScoreTableBox from 'components/tables/ScoreTableBox';
import { tooltipTriggerAriaLabel } from 'components/tables/GradesTable';
import { visuallyHidden } from 'styles/utils';
import { filterBox, popoverSelector } from '../styles';
import styles from './styles';

const links = [
	{
		name: 'progress',
		ui: {
			label: 'Show My Progress',
			description: (
				<>
					The table on this screen gives you an easy way to see all that you’ve done and what’s
					next. Squares with checkmarks{' '}
					<span className={visuallyHidden}>
						<ProgressTableBox pageSummary={complete} />
					</span>
					show pages you’ve completed. Partially filled squares{' '}
					<span className={visuallyHidden}>
						<ProgressTableBox pageSummary={incomplete} />
					</span>
					indicate pages where you can get more points, either by completing unanswered
					questions or by using additional attempts to answer questions correctly.
				</>
			),
		},
	},
	{
		name: 'score',
		ui: {
			label: 'Show My Score',
			description: (
				<>
					The table on this screen shows your score for the multiple-choice questions on each
					webtext page. Click on pages in red with a score under 100%{' '}
					<span className={visuallyHidden}>
						<ScoreTableBox pageSummary={lowScore} />
					</span>
					to see what you missed. When possible, reset the questions and answer again to show
					that you understand these concepts.
				</>
			),
		},
	},
	{
		name: 'timing',
		ui: {
			label: 'Show My Active Time',
			description: (
				<>
					The table on this screen shows the minutes spent clicking, typing, and scrolling in
					the webtext.
				</>
			),
		},
	},
];

const QuickActionsSelector: FC = () => {
	const dispatch = useAppDispatch();

	const overviewPageMatch = useRouteMatch('/courses/:courseId/overview');
	const gradingProfileId = useAppSelector((state) => state.course.grading_profile_id);
	const lmsInstallationId = useAppSelector((state) => state.course.lms_installation_id);
	const activeOverview = useAppSelector((state) => state.overviewCategory);
	const isMediumScreen = useMediaBreakpoint('medium', 'max-width');

	const handleActionClick = () => {
		if (isMediumScreen) dispatch(setNavbarOpen(false));
	};

	const allLinks = useMemo(() => {
		const allLinks = [...links];
		// only add "Show My Gradebook Points" to quick actions if course has a grading profile
		if (gradingProfileId) {
			const description = (
				<>
					The table on this screen shows you the points you have earned for completing webtext
					questions. For each gradebook item, you’ll see the points you have earned as well as
					the total points possible.
					{lmsInstallationId && (
						<>
							{' '}
							Click the icon
							<span className={visuallyHidden}>({tooltipTriggerAriaLabel})</span> in each cell
							to view the transfer status of the points to your school’s LMS gradebook.
						</>
					)}
				</>
			);

			allLinks.push({
				name: 'grades',
				ui: {
					label: 'Show My Gradebook Points',
					description,
				},
			});
		}
		return allLinks;
	}, [gradingProfileId, lmsInstallationId]);

	const activeOverviewLink = useMemo(
		() => (!overviewPageMatch ? null : allLinks.find((link) => link.name === activeOverview)),
		[overviewPageMatch, allLinks, activeOverview]
	);

	return (
		<div className={cn(styles, popoverSelector)}>
			<div className="selector-label-button">
				<div className="selector-label-box">
					<p className="selector-label">Quick Actions</p>
				</div>
			</div>

			<nav>
				{allLinks.map((link) => {
					return (
						<RaisedButton
							key={link.name}
							variant="text"
							component={Link}
							to={`overview?view=${link.name}`}
							classes={{ root: 'selection-button' }}
							aria-current={link.name === activeOverviewLink?.name ? 'location' : undefined}
							data-active={link.name === activeOverviewLink?.name}
							onClick={handleActionClick}
						>
							<span className="selection-label">{link.ui.label}</span>
						</RaisedButton>
					);
				})}
			</nav>

			{activeOverviewLink && (
				<div className={cn(filterBox, 'help-message')}>
					<h4 className={'filter-description-label'}>{activeOverviewLink.ui.label}</h4>
					<p className={'filter-description'}>{activeOverviewLink.ui.description}</p>
				</div>
			)}
		</div>
	);
};

export default QuickActionsSelector;
