import {
	FullStory,
	init as initFullStory,
	isInitialized as isFullStoryInitialized,
} from '@fullstory/browser';
import { useEffect } from 'react';

import { FULLSTORY_ORG_ID, IS_DEVELOPMENT } from '../constants';
import { UserInfo } from '../types';

export const useFullStoryInitialize = (userData: UserInfo, fullstoryEnabled: boolean) => {
	const isFullStoryAllowed = FULLSTORY_ORG_ID && !IS_DEVELOPMENT && fullstoryEnabled;

	useEffect(() => {
		if (isFullStoryInitialized()) return;
		if (!isFullStoryAllowed || !userData) return;

		initFullStory({ orgId: FULLSTORY_ORG_ID });
		FullStory('setIdentity', {
			uid: String(userData.id),
			properties: {
				displayName: userData.name,
				email: userData.email,
				soomoUserType_str: 'User',
				soomoUserRoles_str: userData.roles.join(', '),
			},
		});
	}, [isFullStoryAllowed, userData]);
};
