import { css } from 'emotion';

import { breakpoints } from '@soomo/lib/styles/themes';

import { colors } from 'styles/theme';
import { selectorPanelWidthPx } from '../../SelectorPanel/styles';

const rowWidth = 864;
export const rowWidthPx = `${rowWidth}px`;

const resultsWidth = 148;
export const resultsWidthPx = `${resultsWidth}px`;

export const contentWidth = `${rowWidth - resultsWidth}px`;

const elementStyles = css`
	max-width: ${rowWidthPx};
	padding-left: ${resultsWidthPx};

	.element-content {
		position: relative;
		font-size: 14px;
		max-width: ${contentWidth};

		.element-activity-header {
			font-size: 10px;
			letter-spacing: 0.01px;
			margin-bottom: 4px;
			color: #686868;

			.element-type {
				text-transform: uppercase;
				font-weight: 500;
			}
		}

		.element-body {
			margin: 10px 0;
			font-size: 17px;
		}

		/*
     * Absolutely position it right after the heading 
     * for a better screen readers support
     */

		.element-results {
			position: absolute;
			top: 0;
			left: -${resultsWidthPx};
			width: ${resultsWidthPx};
			text-align: center;

			&.open-ended {
				font-size: 12px;
				font-style: italic;
			}
		}

		.correctness-icon {
			font-size: 24px;
		}

		.element-activity-body {
			margin-left: -${resultsWidthPx};
			margin-top: 16px;

			.activity-header-timestamp {
				width: ${resultsWidthPx};
				text-align: center;
			}

			.activity-header-status {
				margin-left: ${resultsWidthPx};
			}
		}

		.transcript-disclaimer {
			margin-top: 1em;
		}

		p {
			margin-bottom: 0;

			&.comfortable {
				margin-bottom: 1rem;
			}
		}

		.element-response {
			font-size: 14px;

			&.completed {
				border-left: 4px solid #496ad4;
				padding-left: 8px;
			}

			li.mc-question-choice {
				display: flex;
				align-items: baseline;
				column-gap: 10px;
				list-style: none none;
				margin-bottom: 3px;
				padding: 0.25rem 0.5rem;
				max-width: 712px;

				input[type='radio'] {
					width: 1.2em;
					height: 1.2em;
					padding: 0;
					flex-shrink: 0;
					top: 0.2em;
					position: relative;
				}

				&.correct-answer {
					border: thin solid #2ca935;
				}
			}
		}

		ul.element-response {
			margin-top: 10px;
			margin-bottom: 10px;
		}

		.reference-answer {
			margin-top: 16px;
			border-left: 4px solid #22872a;
			padding-left: 8px;
		}

		.error {
			color: #c02027;
			border-left: 4px solid #c02027;
			padding-left: 8px;
			font-style: italic;
		}

		.comment-section {
			margin-left: 0.75rem;

			.instructor-comment {
				color: #c02027;
				font-style: italic;
			}

			margin-top: 1rem;
			margin-bottom: 1rem;
		}

		.expandable-panel {
			// On desktop width, compensate for sidebar and results column
			--leftside-offset: calc(${selectorPanelWidthPx} + ${resultsWidthPx});
			// On medium width, compensate only for results column
			@media (max-width: ${breakpoints.medium}) {
				--leftside-offset: resultsWidthPx;
			}

			width: calc(100vw - var(--leftside-offset));

			&-header {
				margin-top: 16px;
				margin-bottom: 6px;
				font-family: inherit;
			}

			&-container {
				margin-left: -${resultsWidthPx};
				padding-left: 0;
				margin-right: 0;
				padding-right: 20px;
			}

			&-content {
				.activity-header-timestamp {
					width: ${resultsWidthPx};
					text-align: center;
				}

				.activity-header-status,
				.activity-history-item-body,
				.student-answers-list-item {
					margin-left: ${resultsWidthPx};
				}
			}
		}
	}

	.no-answer {
		margin-bottom: 0;
		color: #999;
		font-style: italic;
	}

	b {
		font-weight: bold;
	}

	&.poll-question-element {
		.question-choices {
			padding: 0;

			.question-choice {
				font-size: 14px;

				:not(:last-child) {
					margin-bottom: 12px;
				}

				label {
					padding-left: 1em;
				}

				input[type='radio'] {
					width: 17px;
					height: 17px;
				}
			}
		}

		.review-link,
		.review-link:visited {
			color: ${colors.appAccentColor};
		}
	}
`;

export default elementStyles;
