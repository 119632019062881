import React, { FC, useEffect, useMemo } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useAppSelector } from '../../store';
import { API_HOST } from '../../constants';
import { ErrorMessage } from '../../components';

export enum ErrorType {
	'token-expired' = 'token-expired',
	'token-missing' = 'token-missing',
	'course-id-missing' = 'course-id-missing',
	'course-missing' = 'course-missing',
	'no-permissions' = 'no-permissions',
}

/**
 * Used to display critical application-level errors which can happen in any place, not only visual components.
 * Therefore, we need to be able to get here with the redirection to the /error/* page
 */
const Error: FC = () => {
	const history = useHistory();
	const location = useLocation<{ courseId: string }>();
	const { errorType } = useParams<{ errorType: ErrorType }>();

	const reduxCourseId = useAppSelector((state) => state.courseId);
	const accessToken = useAppSelector((state) => state.accessToken);

	const courseId = location.state?.courseId || reduxCourseId;

	useEffect(() => {
		console.error(`Exception happened: ${errorType}`);
	}, [errorType]);

	/**
	 * Restoration hook. Should forward to the valid page if the conditions are met
	 */
	useEffect(() => {
		switch (errorType) {
			case 'token-missing': {
				if (accessToken) {
					history.push(`/courses/${courseId}`);
				}
				break;
			}
			case 'course-id-missing': {
				if (courseId) {
					history.push(`/courses/${courseId}`);
				}
				break;
			}
			case 'course-missing': {
				if (!courseId) {
					history.push('/error/course-id-missing');
				}
				break;
			}
			case 'no-permissions': {
				if (!courseId) {
					history.push('/error/course-id-missing');
				}
				break;
			}
		}
	}, [accessToken, courseId, errorType, history]);

	const { errorTitle, error } = useMemo(() => {
		switch (errorType) {
			case 'token-expired': {
				return {
					errorTitle: 'Your token has expired',
					error: (
						<>
							Please sign in again on <a href={API_HOST}>{API_HOST}</a>
						</>
					),
				};
			}
			case 'token-missing': {
				return {
					errorTitle: 'Your token is missing',
					error: (
						<>
							Please sign in on <a href={API_HOST}>{API_HOST}</a> and return to the site later
						</>
					),
				};
			}
			case 'course-id-missing': {
				return {
					errorTitle: "Your course wasn't selected",
					error: (
						<>
							Please select the course on <a href={API_HOST}>{API_HOST}</a> and return to the
							site later
						</>
					),
				};
			}
			case 'no-permissions': {
				return {
					errorTitle: `You're not permitted to view the course #${courseId} data`,
					error: (
						<>
							Please select the appropriate course on <a href={API_HOST}>{API_HOST}</a> and
							return to the site later
						</>
					),
				};
			}
			default: {
				return {
					errorTitle: 'Unknown exception happened',
					error: (
						<>
							Please retry to visit this page from <a href={API_HOST}>{API_HOST}</a>
						</>
					),
				};
			}
		}
	}, [courseId, errorType]);

	return (
		<ErrorMessage title={errorTitle} showDefaultMessage={false}>
			{error}
		</ErrorMessage>
	);
};

export default Error;
