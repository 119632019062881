import { css } from 'emotion';
import { colors, fonts } from 'styles/theme';

const headerHeight = 56;
export const headerHeightPx = `${headerHeight}px`;

export default css`
	height: ${headerHeightPx};
	background-color: ${colors.darkNavy2};
	color: white;
	font-family: ${fonts.headingFontFamily};

	.toolbar {
		height: 100%;
		padding-left: 0;
		font-size: 0.9em;
		font-weight: normal;
		line-height: 1.5;

		.home {
			height: 100%;
			margin-right: 0;
			padding-left: 24px;
			font-size: inherit;
			line-height: inherit;
			font-weight: bold;
			text-transform: uppercase;

			.MuiSvgIcon-root {
				font-size: 24px;
			}
		}

		.slash {
			margin: 0 1ch;
			color: ${colors.appAccentColor};
			user-select: none;
		}

		.course-name {
			height: 100%;
			margin-right: 0;
			color: ${colors.appAccentColor};
			font-size: inherit;
			font-weight: inherit;
			line-height: inherit;
			text-decoration-line: underline;
			text-underline-offset: 4px;
		}

		.student-name {
			margin-left: 1ch;
			color: ${colors.darkWhite};
		}

		.toc-toggle-button {
			margin-left: 10px;
			color: white;
		}
	}
`;
