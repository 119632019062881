import { css } from 'emotion';

const styles = css`
	display: flex;
	flex-direction: column;
	height: 100vh;
	max-height: 100vh;
	background-image: none;

	#app-body {
		display: flex;
		flex-grow: 1;
		overflow: hidden;
	}
`;

export const loaderTextStyles = css`
	color: #0056b3;
	font-size: 28px;
`;

export default styles;
