import { css } from 'emotion';

export default css`
	color: #ffffff;
	font-weight: 500;
	font-size: 15px;
	letter-spacing: 1px;
	animation: fadeInOut linear 3.5s infinite;

	@keyframes fadeInOut {
		0% {
			opacity: 0;
		}
		50% {
			opacity: 1;
		}
		100% {
			opacity: 0;
		}
	}
`;
