import React, { FC } from 'react';
import { DateTime } from 'luxon';
import cn from 'classnames';

import { NotebookPageSummary } from '@soomo/lib/notebook/types';
import { Page } from '@soomo/lib/types/WebtextManifest';
import { formatDateTime, formatTimeSpent, formatTimestamp } from '@soomo/lib/formatters';

import styles from './styles';

const Line = () => <span className="line">|</span>;

const BoldItem = (props) => <span className="bold">{props.children}</span>;

const LabeledItem = (props) => (
	<span>
		<BoldItem>{props.label}</BoldItem>
		<span>{props.children}</span>
	</span>
);

const formatLastDate = (date: string) => (date ? formatTimestamp(date) : 'Never');

const getDueDateInfo = (page) => {
	if (!page.dueAt) return null;

	const dueAt = DateTime.fromISO(page.dueAt);
	const now = DateTime.local();
	const dueAtTimestamp = formatTimestamp(page.dueAt);

	return dueAt <= now ? (
		<LabeledItem label="Past Due:">Originally due on {dueAtTimestamp}</LabeledItem>
	) : (
		<LabeledItem label="Due:">{dueAtTimestamp}</LabeledItem>
	);
};

const getPenaltyPeriodInfo = (page) => {
	if (!page.dueAt) return null;
	if (page.dueAt === page.penaltyPeriodEndsAt) return null;
	if (page.pastDuePenaltyFactor === 0) return null;

	const dueAt = DateTime.fromISO(page.dueAt);
	const penaltyPeriodEndsAt = DateTime.fromISO(page.penaltyPeriodEndsAt);
	const now = DateTime.local();

	if (now < dueAt) return null;
	return (
		<LabeledItem label="Penalty:">
			{now < penaltyPeriodEndsAt ? (
				<>Reduced credit period ends on </>
			) : (
				<>Reduced credit period ended on </>
			)}
			{formatDateTime(penaltyPeriodEndsAt, "MMM dd, yyyy, 'at' hh:mm a ZZZZ")}
		</LabeledItem>
	);
};

export interface PageInfoHeaderProps {
	pageSummary: NotebookPageSummary; // Corresponds to the pageActivity in A2
}

const PageInfoHeader: FC<PageInfoHeaderProps> = ({ pageSummary }) => {
	const lastViewedInfo = formatLastDate(pageSummary.lastActivityAt);
	const lastUpdateInfo = formatLastDate(pageSummary.lastSavedAt);
	const timeSpentInfo = formatTimeSpent(pageSummary.totalTimeSpent);
	const dueDateInfo = getDueDateInfo(pageSummary);
	const penaltyPeriodInfo = getPenaltyPeriodInfo(pageSummary);

	return (
		<div className={cn('page-info-header', styles)}>
			<div className={'header-row'}>
				<LabeledItem label="Last Viewed:">{lastViewedInfo}</LabeledItem>
				<Line />
				<LabeledItem label="Last Update:">{lastUpdateInfo}</LabeledItem>
				<Line />
				<LabeledItem label="Time Spent:">{timeSpentInfo}</LabeledItem>
				<Line />

				{pageSummary.questionsPossible === 0 ? (
					<BoldItem>No Questions</BoldItem>
				) : (
					<>
						<LabeledItem label="Completed:">
							{pageSummary.questionsCompleted}/{pageSummary.questionsPossible}
						</LabeledItem>
						{pageSummary.questionsScored > 0 && (
							<>
								<Line />
								<LabeledItem label="Score:">
									{pageSummary.questionsCorrect}/{pageSummary.questionsScored}
								</LabeledItem>
							</>
						)}
						{pageSummary.questionsInstructorGradable > 0 && (
							<>
								<Line />
								<LabeledItem label="Graded:">
									{pageSummary.questionsGraded}/{pageSummary.questionsInstructorGradable}
								</LabeledItem>
							</>
						)}
					</>
				)}

				{pageSummary.limitedAttempts && pageSummary.questionsScored !== 0 && (
					<>
						<Line />
						<BoldItem>
							{pageSummary.resetsRemaining ? 'Resets Remaining' : 'No Resets Remaining'}
						</BoldItem>
					</>
				)}
			</div>
			<div className={'header-row'}>
				{dueDateInfo && (
					<>
						{dueDateInfo}
						{penaltyPeriodInfo && (
							<>
								<Line /> {penaltyPeriodInfo}
							</>
						)}
					</>
				)}
			</div>
		</div>
	);
};

export default PageInfoHeader;
