import { createTheme } from '@mui/material';
import { colors } from 'styles/theme';

/**
 * Theme will allow us to easily override the button styles with the props
 * Also it won't require to merge the props styles with the internal ones
 */
export const raisedButtonTheme = createTheme({
	palette: {
		primary: {
			main: colors.lavender,
		},
	},
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					height: '30px',
					minHeight: '30px',
					padding: '0 16px',
					marginRight: '5px',
					paddingLeft: '16px',
					paddingRight: '16px',
					borderRadius: '2px',
					fontFamily: 'inherit',
					fontWeight: '300',
					fontSize: '0.8em',
					lineHeight: '30px',
					color: 'white',
					textTransform: 'none',

					'&.Mui-disabled': {
						backgroundColor: colors.blueDisabled,
					},
				},
			},
		},
	},
});
