import { css } from 'emotion';

export default css`
	width: 9px;

	span {
		display: inline-block;
		width: 2px;
		height: 2px;
		background-color: black;
		margin-right: 1px;
		float: left;
	}
`;
