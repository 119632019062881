import { css } from 'emotion';
import { fadeIn } from 'styles/mixins/fade';
import { colors } from 'styles/theme';

const styles = css`
	.sidebar {
		font-size: 12px;
		font-style: italic;
	}
	.draft-added {
		margin-top: 0.5rem;
	}

	.prompts {
		${fadeIn(600)};
	}

	.prompt-element {
		margin-bottom: 0.7em;
		.fill-in,
		.fill-in-presentation-inline {
			border: 1px solid #333;
			padding: 4px;
		}

		.fill-in {
			margin-top: 0.3em;
		}

		.fill-in-presentation-inline {
			line-height: 32px;
		}

		.redactor-layer {
			p {
				margin-top: 0;
			}
		}
	}

	textarea {
		width: 100%;
	}

	.inline_prompt_item.user_input_prompt_item {
		line-height: 2em;
	}

	.block_prompt_item {
		margin: 6px 0;

		p {
			margin-top: 0;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	.user_input_prompt_item {
		border: 1px solid ${colors.cobaltBlue};
		padding: 3px;
		overflow-wrap: break-word;
		/* IE11 does not live by your rules
		https://developer.mozilla.org/en-US/docs/Web/CSS/overflow-wrap */
		word-wrap: break-word;
	}

	.blank_user_input_prompt_item {
		&::before {
			content: 'No response provided.';
			font-style: italic;
			font-size: smaller;
			margin-left: 0.5rem;
			color: #999;
		}
	}

	.blank_user_input_prompt_item.inline_prompt_item {
		&::before {
			content: 'no response provided';
		}
	}

	.locked-message {
		padding: 0;
		margin: 20px 0;
		color: #000;

		font-size: 13px;

		h4 {
			font-weight: normal;
			text-transform: uppercase;
			margin-bottom: 0.5em;
		}

		ul {
			list-style: none;
		}
	}

	.UploadedFile {
		object-fit: scale-down;
		width: 100%;
		max-height: 600px;
		border-radius: 0.1px;
		font-family: 'object-fit: scale-down;';
		border: 2px solid rgba(#1f1f1f, 0.11);
		padding: 15px;
		text-align: center;
	}
`;

export default styles;
