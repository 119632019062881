import { css } from 'emotion';

export default css`
	position: relative;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	flex-shrink: 0;
	width: 22px;
	height: 22px;
	border: 1px solid #496ad4;
	color: #496ad4;
	border-radius: 2px;
	background-color: #f0f5ff;

	svg {
		height: 100%;
	}

	&.partially-complete,
	&.no-longer-completable {
		align-items: flex-end;
		background-color: #d5152c;

		.fill-box {
			width: 100%;
			height: 100%;
			background-color: #f0f5ff;
		}
	}

	&.no-longer-completable {
		background-color: gray;
		background: repeating-linear-gradient(-45deg, #777, #777 2px, #ccc 2px, #ccc 6px);
	}

	&.not-attempted {
		border: none;
		background: none;
	}

	&.no-longer-attemptable {
		border: none;
		background: none;
	}

	.line {
		width: 8px;
		height: 2px;
		background-color: black;

		&.red {
			background-color: #d5152c;
		}
	}
`;
