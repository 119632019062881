import { css } from 'emotion';
import { resultsWidthPx, rowWidthPx } from 'components/page_view/elements/element_styles';
import { colors } from 'styles/theme';

const styles = css`
	ul {
		padding-left: 0;
	}

	.attempt-history {
		max-width: ${rowWidthPx};

		.attempts {
			border-top: thin solid #dce7ff;
			margin-bottom: 16px;
			margin-left: 42px;
			font-size: 14px;

			.attempt {
				display: flex;
				align-items: flex-start;
				margin-top: 0.5rem;
				margin-bottom: 0.5rem;

				.date-time {
					flex-shrink: 0;
					width: 120px;
					color: #686868;
					font-size: 13px;
					margin-top: 2px;
					margin-right: 5px;
					margin-left: -10px;
				}

				svg {
					flex-shrink: 0;
					margin-right: 12px;

					width: 14px;
				}
			}
		}

		.toggle-attempt-history {
			min-height: 24px;
			height: 24px;
			margin-left: ${resultsWidthPx};
			border-radius: 5px;
			color: ${colors.mediumDarkLavender};
			font-size: 10px;
			line-height: 10px;
			letter-spacing: 0.01px;

			&:hover,
			&:focus-visible {
				color: white;
				background-color: ${colors.lighterBlue};
			}
		}
	}
`;

export default styles;
