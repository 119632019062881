import { css } from 'emotion';
import { colors } from 'styles/theme';

export const listItem = css`
	color: white;
	font-size: 13px;
	font-weight: normal;
	cursor: pointer;
	user-select: none;
	padding: 0;
`;

export const listItemLabel = css`
	height: 100%;
	flex-grow: 1;
	justify-content: start;
	color: inherit;
	padding-left: 16px;
	font-family: inherit;
	font-size: inherit;
	font-weight: inherit;
	line-height: 24px;
	text-align: left;

	&:disabled {
		color: inherit;
	}
`;

export const chapterListItem = css`
	${listItem};
	align-items: center;

	&:hover,
	&:focus-within {
		background: ${colors.mediumDarkLavender};
	}

	.chapter-label {
		${listItemLabel};
		text-transform: uppercase;
	}

	.chapter-collapse-toggle {
		color: inherit;
		width: 24px;
		height: 24px;
		margin-left: 5px;
		margin-right: 5px;
	}
`;

export const pageListItem = css`
	${listItem};
	height: min-content;
	color: ${colors.tocEntry};

	span {
		text-indent: 10px;
		line-height: 1.3;
	}

	.page-label {
		${listItemLabel};
		padding-top: 5px;
		padding-bottom: 5px;
		text-transform: none;
	}

	&:hover,
	&:focus-within {
		background: ${colors.darkLavender};
	}

	&[data-within-group='true'] {
		span {
			text-indent: 20px;
		}
	}
`;

export const pageGroupListItem = css`
	${listItem};
	color: ${colors.tocEntry};

	.page-group-label {
		${listItemLabel};
		font-size: 15px;
	}
`;
