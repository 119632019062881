export const API = {
	ACCESS_TOKEN: '/api/auth/v1/token',
	CURRENT_USER_INFO: '/api/users/v1/info',
	INITIAL_NOTEBOOK_STATE: '/analytics/student_notebook/initial_state',
	ELEMENTS_ACTIVITY: '/analytics/student_notebook/elements_activity',
	NOTEBOOK_SUMMARY: '/analytics/student_notebook/notebook_summary',
	BACKGROUND_REFRESH: '/analytics/student_notebook/background_refresh',
	NOTIFICATIONS: '/analytics/student_notebook/notifications',
	EVENTS: '/api/courses/v1/events',
};
