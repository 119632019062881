import React, { ReactElement } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators } from 'redux';

import { webtextLinkForPage } from '@soomo/lib/utils';
import { getElementsForPage, getParamsFromURL } from '@soomo/lib/notebook/utils';
import { UserDecisions } from '@soomo/lib/notebook/types';
import { Page } from '@soomo/lib/types/WebtextManifest';

import { fetchElementsActivity, setActivePage } from 'store/actions';
import { elementsActivityForPage } from 'store/selectors';
import NotebookPageElement, {
	getShouldRenderElement,
} from 'components/page_view/notebook_page_element';
import PageInfoHeader from 'components/page_view/PageInfoHeader';
// import ErrorBoundary from "@soomo/components/error_boundary";
import { CircularLoader, ErrorMessage, RaisedButton } from 'components';
import { StudentNotebookAppState } from '../../types';
import { API_HOST } from '../../constants';
import { Link } from 'react-router-dom';
import styles from './styles';
import { joinWithOxfordComma } from '@soomo/lib/utils/formatting';

type PageViewScreenProps = PageViewScreenReduxProps;

class PageViewScreen extends React.Component<PageViewScreenProps> {
	componentDidMount() {
		this.props.setActivePage(this.props.pageId);
		if (!this.props.elementsActivity) {
			this.props.fetchElementsActivity(this.props.pageId);
		}

		const { pageNumber, name: pageName } = this.props.pageSummary;
		const { chapter_number } = this.props.chapter;
		document.title = `${pageNumber} ${pageName} | Chapter ${chapter_number}`;
	}

	componentDidUpdate(prevProps) {
		const pageId = getParamsFromURL().page_id;
		if (prevProps.pageId === pageId) return;

		this.props.setActivePage(pageId);
		if (!this.props.elementsActivity) {
			this.props.fetchElementsActivity(this.props.pageId);
		}
	}

	renderElements() {
		const { manifest, course, coursePolicy, elements, elementsActivity, page, userDecisions } =
			this.props;

		// Todo: remove this prop threading and connect page elements directly
		return elements
			.map((element, i) => {
				let elementAnswers = [];
				let correctChoiceFamilyId = null;
				let elementActivity = null;
				if (elementsActivity != null) {
					elementActivity = elementsActivity[element.id];
					if (elementActivity != null) {
						elementAnswers = elementActivity.answers;
						correctChoiceFamilyId = elementActivity.correctChoiceFamilyId;
					}
				}

				return getShouldRenderElement(element, userDecisions) ? (
					<NotebookPageElement
						key={element.id}
						manifest={manifest}
						element={element}
						page={page}
						answers={elementAnswers}
						course={course}
						coursePolicy={coursePolicy}
						elementActivity={elementActivity}
						correctChoiceFamilyId={correctChoiceFamilyId}
						userDecisions={userDecisions}
					/>
				) : null;
			})
			.filter(Boolean);
	}

	render() {
		const {
			courseId,
			pageSummary,
			page,
			chapter,
			userDecisions,
			overviewCategory,
			loading,
			error,
		} = this.props;

		let pageContent: ReactElement | Array<ReactElement>;
		if (error) {
			pageContent = <ErrorMessage title="Error loading page">{error}</ErrorMessage>;
		} else if (loading) {
			pageContent = <CircularLoader showAfterMS={700} />;
		} else {
			const elements = this.renderElements();
			pageContent = elements.length > 0 ? elements : <h2 id="no-questions">No Questions</h2>;
		}

		const missingDependenciesList = getMissingDependenciesList({ page, userDecisions });

		const links = (
			<div className="links">
				<RaisedButton
					component={Link}
					to={`/courses/${courseId}/overview?view=${overviewCategory}`}
				>
					Back to Overview
				</RaisedButton>
				<RaisedButton
					href={webtextLinkForPage(courseId, chapter.version, page.version, API_HOST)}
					target="_blank"
				>
					Go to Page
				</RaisedButton>
			</div>
		);

		return (
			<div className={styles}>
				<PageInfoHeader pageSummary={pageSummary} />
				{links}
				{missingDependenciesList.length > 0 ? (
					<p className="MissingDependenciesMessage">
						{`Content on this page can only be shown once you’ve chosen your ${missingDependenciesList}.`}
					</p>
				) : (
					<div id="webtext-page-elements">{pageContent}</div>
				)}
			</div>
		);
	}
}

const getMissingDependenciesList = ({
	page,
	userDecisions,
}: {
	page: Page;
	userDecisions: UserDecisions;
}) => {
	const pageDependencies = {};
	page.internal_dependencies.forEach((dep) => (pageDependencies[dep] = true));
	page.external_dependencies.forEach((dep) => (pageDependencies[dep] = true));

	Object.entries(userDecisions)
		.filter(([_, decisions]) => decisions)
		.forEach(([dep]) => delete pageDependencies[dep]);

	return joinWithOxfordComma(
		Object.keys(pageDependencies).map((dep) => dep.replace('_', ' ').toLowerCase())
	);
};

const mapStateToProps = (state: StudentNotebookAppState) => {
	if (state.async.initialState) return {};

	const { userDecisions } = state.notebookSummary;
	const pageId = getParamsFromURL().page_id;
	const page = state.manifest.pages[pageId];
	const chapter = state.manifest.chapters[page.chapter_id];
	const elements = getElementsForPage(state.manifest, pageId, userDecisions);
	const elementsActivity = elementsActivityForPage(state, pageId);
	const error = state.errors.elementsActivity[pageId];
	const loading =
		(!elementsActivity && !error) ||
		state.async.elementsActivity[pageId] ||
		(state.async.notebookSummary && !state.notebookSummary);

	return {
		pageId,
		page,
		elements,
		loading,
		course: state.course,
		coursePolicy: state.coursePolicy,
		error,
		userDecisions,
		manifest: state.manifest,
		courseId: state.courseId,
		chapter,
		pageSummary: state.notebookSummary.pageMap[pageId],
		elementsActivity,
		overviewCategory: state.overviewCategory,
	};
};

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			fetchElementsActivity: fetchElementsActivity.request,
			setActivePage,
		},
		dispatch
	);

const connector = connect(mapStateToProps, mapDispatchToProps);

type PageViewScreenReduxProps = ConnectedProps<typeof connector>;

export default connector(PageViewScreen);
