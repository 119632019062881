import { css } from 'emotion';
import { colors } from 'styles/theme';

export default css`
	display: flex;
	align-items: center;
	justify-content: center;
	background: ${colors.darkBlue};
	padding: 1em;
	margin: 0;
	border: 1px solid ${colors.darkBlue};
	border-radius: 3px;
	color: ${colors.offWhite};

	.message {
		font-size: 0.8em;
	}

	.header {
		h1 {
			font-size: 1em;
			font-weight: bold;
		}
	}

	.due-date-and-penalty-info {
		display: flex;
		flex-direction: column;
		gap: 3px;
		margin-bottom: 10px;
	}

	.penalty-period-info {
		font-style: italic;
	}

	.content {
		padding-left: 10px;
		border-left: 2px solid ${colors.blue};
		line-height: 1.5em;
		font-size: 0.8em;

		h2 {
			padding: 0;
			margin: 0.15em 0;
			font-size: 1.05em;
			font-weight: normal;
			text-transform: uppercase;
		}

		p {
			padding: 0;
			margin: 0.15em 0;
			font-weight: normal;
		}

		strong {
			font-weight: 700;
		}

		.label {
			color: ${colors.lighterBlue};
			font-weight: 500;
			text-transform: uppercase;
			margin-right: 5px;

			&.not-first-label {
				margin-left: 10px;
			}
		}
	}

	.clock {
		display: inline-block;
		position: relative;
		top: 2px;
		fill: ${colors.orange};
		height: 100%;
	}

	.footer {
		.note {
			font-size: 0.6em;
			font-weight: normal;
			line-height: 1.4;
		}

		.clock {
			font-size: 14px;
			margin-right: 5px;
		}
	}
`;
