import React from 'react';
import cn from 'classnames';
import { formatTimeShort } from '@soomo/lib/utils/formatting';
import { MCQuestionElement } from '@soomo/lib/types/WebtextManifest';
import { MCQuestionAnswer } from '@soomo/lib/notebook/types';
import { Characters } from '@soomo/lib/utils';
import { formatTimestamp } from '@soomo/lib/formatters';
import elementStyles from 'components/page_view/elements/element_styles';
import IconCorrectness from '../IconCorrectness';
import styles from './styles';
import { RaisedButton } from '../../../index';

export interface Props {
	label?: string; // custom element label, such as "Get the Gist"; default: "Multiple-Choice Question"
	element: MCQuestionElement;
	answers: MCQuestionAnswer[];
	elementActivity?: any;
	/**
	 * Used to show the correct answer when
	 * - no attempts left & due date has passed
	 * - the answer is correct
	 */
	correctChoiceFamilyId?: string;
}

interface State {
	showAttemptHistory: boolean;
}

export const mcDefaultLabel = 'Multiple-Choice Question';

class MCQuestion extends React.Component<Props, State> {
	constructor(props) {
		super(props);
		this.state = {
			showAttemptHistory: false,
		};
	}

	toggleAttemptHistory = () =>
		this.setState({ showAttemptHistory: !this.state.showAttemptHistory });

	renderHeader() {
		const { answers, label, elementActivity } = this.props;
		const answer = this.mostRecentAnswer();
		const attemptCount = answers ? answers.length : 0;
		let activityInfo = null;
		if (answer) {
			const attempts = `${attemptCount} ATTEMPT${attemptCount != 1 ? 'S' : ''}`;
			const timeStamp = `LATEST ATTEMPT ${formatTimestamp(answer.saved_at)}`;
			activityInfo = ` ${Characters.emdash} ${attempts} ${Characters.emdash} ${timeStamp}`;
		}

		return (
			<div className="element-activity-header">
				<span className="element-type">{label || mcDefaultLabel}</span>
				{activityInfo}
				{elementActivity.past_due && ` ${Characters.emdash} PAST DUE`}
				{elementActivity.no_more_attempts && ` ${Characters.emdash} NO ATTEMPTS REMAINING`}
			</div>
		);
	}

	renderAttemptHistory() {
		const { showAttemptHistory } = this.state;
		const { answers, element } = this.props;
		const mostRecentAnswer = this.mostRecentAnswer();
		const attemptHistory = answers.slice().reverse();
		return (
			<div className="attempt-history">
				{showAttemptHistory && answers.length > 0 && (
					<div className="attempts">
						{attemptHistory.map((a, i) => {
							const choice = element.choices.find((c) => c.id === a.body);
							return (
								<div key={i} className="attempt">
									<span className="date-time">
										{formatTimeShort({
											time: a.saved_at,
											timeZone: 'local',
											withoutTimeZone: true,
										})}
									</span>
									<IconCorrectness correct={a.correct} time={'past'} />
									<span
										className="answer-body"
										dangerouslySetInnerHTML={{ __html: choice.body }}
									/>
								</div>
							);
						})}
					</div>
				)}
				<RaisedButton
					variant="outlined"
					disableFocusRipple
					onClick={this.toggleAttemptHistory}
					classes={{ root: 'toggle-attempt-history' }}
				>
					{showAttemptHistory ? 'Hide' : 'Show'} Attempt History
				</RaisedButton>
			</div>
		);
	}

	mostRecentAnswer() {
		return this.props.answers ? this.props.answers[this.props.answers.length - 1] : null;
	}

	render() {
		const { showAttemptHistory } = this.state;
		const { element, answers, correctChoiceFamilyId } = this.props;

		const mostRecentAnswer = this.mostRecentAnswer();
		const showHistoryToggle = answers && answers.length > 0;

		const isCorrect = mostRecentAnswer?.correct;
		const isReset = mostRecentAnswer?.reset_at;

		return (
			<div className={cn('page-element mc-question-element', styles)}>
				<div className={elementStyles}>
					<div className="element-content">
						{this.renderHeader()}
						<h2 className="element-body" dangerouslySetInnerHTML={{ __html: element.body }} />
						<div className="element-results">
							{mostRecentAnswer && !isReset && <IconCorrectness correct={isCorrect} />}
						</div>
						<ul className="element-response">
							{element.choices.map((c, i) => {
								const correct = c.id === correctChoiceFamilyId;
								const checked = mostRecentAnswer && !isReset && mostRecentAnswer.body == c.id;
								return (
									<li
										key={c.id}
										className={cn('mc-question-choice', { 'correct-answer': correct })}
									>
										<input
											type="radio"
											id={`choice_${c.id}`}
											value={i.toString()}
											checked={checked}
											disabled
										/>
										<label
											htmlFor={`choice_${c.id}`}
											dangerouslySetInnerHTML={{ __html: c.body }}
										/>
									</li>
								);
							})}
						</ul>
					</div>
				</div>

				<div>{showHistoryToggle && this.renderAttemptHistory()}</div>
			</div>
		);
	}
}

export default MCQuestion;
