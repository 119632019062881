import { applyMiddleware, compose, createStore } from 'redux';
import { effectsMiddleware } from 'redux-effex';
import appReducer from 'store/reducer';
import effects from 'store/effects';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { StudentNotebookAppState } from '../types';
import { IS_DEVELOPMENT } from '../constants';

const configureStore = () => {
	const composeEnhanced = IS_DEVELOPMENT
		? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
		: compose;
	const enhancer = composeEnhanced(applyMiddleware(effectsMiddleware(effects)));
	return createStore(appReducer, enhancer);
};

const store = configureStore();

// TODO Uncomment after migration to the RTK
// export type RootState = ReturnType<typeof store.getState>;

export type RootState = StudentNotebookAppState;
export type AppDispatch = typeof store.dispatch;

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAppDispatch = (): AppDispatch => useDispatch<AppDispatch>();

export default store;
