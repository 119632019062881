import React, { FC, useMemo } from 'react';
import ListItem from '@mui/material/ListItem';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import { chapterListItem, pageGroupListItem, pageListItem } from './styles';

const onListItemClick = (id, onSelect) => (event) => {
	if (!id) return;

	event.stopPropagation();
	onSelect(id);
};

const getPageListItems = ({ pages, onPageSelect, visible, withPageGroup = false }) =>
	pages.map(({ id, label }) => (
		<ListItem key={id} data-within-group={withPageGroup} classes={{ root: pageListItem }}>
			<Button
				disabled={!visible}
				onClick={onListItemClick(id, onPageSelect)}
				classes={{ root: 'page-label' }}
			>
				<span dangerouslySetInnerHTML={{ __html: label }} />
			</Button>
		</ListItem>
	));

const getPageGroupListItems = ({ pageGroups, onPageSelect, visible }) =>
	pageGroups.map(({ id, label, pages }) => {
		const firstPageId = pages[0]?.id;

		return [
			<ListItem key={id} classes={{ root: pageGroupListItem }}>
				<Button
					disabled={!visible}
					onClick={onListItemClick(firstPageId, onPageSelect)}
					classes={{ root: 'page-group-label' }}
				>
					<span dangerouslySetInnerHTML={{ __html: label }} />
				</Button>
			</ListItem>,
			pages.length > 0 &&
				getPageListItems({
					pages,
					onPageSelect,
					withPageGroup: true,
					visible,
				}),
		];
	});

export interface ChapterListItemProps {
	chapterOutline: any;
	open: boolean;
	onChapterToggle: (chapterId: string) => void;
	onPageSelect: (pageId: string) => void;
}

const ChapterListItem: FC<ChapterListItemProps> = ({
	open,
	chapterOutline,
	onChapterToggle,
	onPageSelect,
}) => {
	const chapterItems = useMemo(
		() =>
			chapterOutline.hasPageGroups
				? getPageGroupListItems({
						pageGroups: chapterOutline.pageGroups,
						onPageSelect,
						visible: open,
				  })
				: getPageListItems({
						pages: chapterOutline.pages,
						onPageSelect,
						visible: open,
				  }),
		[chapterOutline, open, onPageSelect]
	);

	return (
		<>
			<ListItem
				key={chapterOutline.id}
				classes={{ root: chapterListItem }}
				onClick={() => onChapterToggle(chapterOutline.id)}
			>
				<Button classes={{ root: 'chapter-label' }} aria-expanded={!!open}>
					<span dangerouslySetInnerHTML={{ __html: chapterOutline.label }} />
				</Button>
				{chapterItems.length > 0 && !chapterOutline.singleCollapsedPage && (
					<IconButton
						aria-hidden={true}
						tabIndex={-1}
						classes={{ root: 'chapter-collapse-toggle' }}
					>
						{open ? <ExpandLess /> : <ExpandMore />}
					</IconButton>
				)}
			</ListItem>
			{chapterItems.length > 0 && !chapterOutline.singleCollapsedPage && (
				<Collapse key={`${chapterOutline.id}-collapse`} in={open}>
					{chapterItems}
				</Collapse>
			)}
		</>
	);
};

export default ChapterListItem;
