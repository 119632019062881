import React from 'react';
import cn from 'classnames';
import { DecisionElement } from '@soomo/lib/types/WebtextManifest';
import IconCorrectness from '../IconCorrectness';
import elementStyles from 'components/page_view/elements/element_styles';

interface Props {
	element: DecisionElement;
	decisionOptions: any[];
	decision: string;
}

const DecisionPoint = (props: Props) => {
	const { element, decision, decisionOptions } = props;
	const selectedProblem = decision ? decisionOptions.find((d) => d.key === decision) : null;
	return (
		<div className={cn(elementStyles, 'page-element decision-point-element')}>
			<div className="element-content">
				<div className="element-activity-header">
					<span className="element-type">Decision Point</span>
				</div>
				<h2 className="element-body" dangerouslySetInnerHTML={{ __html: element.prompt }} />
				<div className="element-results">{selectedProblem && <IconCorrectness />}</div>
				<div className="element" />
				{decision ? (
					<div
						className="element-response completed"
						dangerouslySetInnerHTML={{ __html: selectedProblem.name }}
					/>
				) : (
					<div className="element-response">
						<p className="no-answered">No decision made</p>
					</div>
				)}
			</div>
		</div>
	);
};

export default DecisionPoint;
