import React, { FC, useMemo } from 'react';
import cn from 'classnames';
import { visuallyHidden } from 'styles/utils';
import { IconCorrect, IconIncorrect } from '../../../icons';
import styles from './style';

interface IconCorrectnessProps {
	correct?: boolean;
	time?: 'current' | 'past'; // To differentiate between 'Is correct' /  'Was correct'
	hideAlternativeText?: boolean;
}

const IconCorrectness: FC<IconCorrectnessProps> = ({
	correct = true,
	time = 'current',
	hideAlternativeText,
}) => {
	const alternativeText = useMemo(() => {
		if (time === 'current') {
			return correct ? 'Is correct' : 'Is incorrect';
		}
		return correct ? 'Was correct' : 'Was incorrect';
	}, [correct, time]);

	return (
		<span className={cn(styles)}>
			{correct ? <IconCorrect /> : <IconIncorrect />}
			{!hideAlternativeText && <span className={visuallyHidden}>{alternativeText}</span>}
		</span>
	);
};

export default IconCorrectness;
