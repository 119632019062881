import { css } from 'emotion';

import { colors } from 'styles/theme';

const completePill = css`
	display: flex;
	align-items: center;
	justify-content: center;
	min-width: 2.65em;
	min-height: 0.85em;
	border-radius: 3px;
	color: white;
`;

export default css`
	position: relative;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	width: 22px;
	height: 22px;

	&.blank {
		opacity: 0;
	}

	&.complete {
		color: #006adb;
	}

	&.partially-complete {
		${completePill};
		background: #d5152c;
	}

	&.no-longer-completable,
	&.no-longer-attemptable {
		color: #6e6e6e;
	}

	.line {
		width: 8px;
		height: 2px;
		background: black;

		&.red {
			background-color: #d5152c;
		}
	}
`;
