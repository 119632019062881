import React from 'react';

export const IconCheck = () => (
	<svg width="12px" height="9px" viewBox="0 0 12 9" version="1.1">
		<g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g id="Box/RED/All-Correct" transform="translate(-5.000000, -7.000000)" fill="#5980FF">
				<g id="Checkmark" transform="translate(5.000000, 7.000000)">
					<path d="M10.7992679,8.48210391e-14 L11.9719813,1.17271339 L4.08826209,9.05643263 L7.1942452e-14,4.96817054 L1.19133504,3.7768355 L4.10688374,6.6923842 L10.7992679,8.48175696e-14 Z" />
				</g>
			</g>
		</g>
	</svg>
);

export const IconCorrect = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 20.17" width="26px" height="21px">
		<path
			fill="#22872a"
			className="cls-1"
			d="M21.89,0,26,4.05,9.83,20.17,0,10.34,3.93,6.41l5.78,5.78Z"
		/>
	</svg>
);

export const IconIncorrect = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" width="21px" height="21px">
		<polygon
			fill="#ff0243"
			points="21.81 4.68 17.13 0 10.9 6.23 4.68 0 0 4.68 6.23 10.9 0 17.13 4.68 21.81 10.9 15.58 17.13 21.81 21.81 17.13 15.58 10.9 21.81 4.68"
		/>
	</svg>
);
