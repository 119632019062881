import {
	ElementsActivityResponse,
	ElementUnreadNotificationsResponse,
	NotebookSummary,
	RefreshDataResponse,
} from '@soomo/lib/notebook/types';
import axios, { AxiosPromise } from 'axios';
import qs from 'qs';
import { InitialNotebookAsyncState, UserInfo } from 'types';
import { API_HOST } from '../constants';
import { history } from '../navigation/Routing';
import { API } from './api_routes';

const axiosInstance = axios.create({ baseURL: API_HOST });

axiosInstance.interceptors.request.use((config) => {
	const accessToken = localStorage.getItem('accessToken');
	config.headers.Authorization = config.headers.Authorization ?? `Bearer ${accessToken}`;
	return config;
});

axiosInstance.interceptors.response.use((response) => {
	const responseUrl = response.request.responseURL;
	const courseId = response?.config?.params?.course_id;

	if (responseUrl?.includes('session_missing')) {
		history.replace('/error/token-expired', { courseId });
	}
	return response;
}, null);

axiosInstance.interceptors.response.use(null, (error) => {
	const { response } = error;

	const responseStatus = response?.status;
	const courseId = response?.config?.params?.course_id;

	if (responseStatus === 401) {
		history.replace('/error/token-expired', { courseId });
	}
	if (responseStatus === 403) {
		history.replace('/error/no-permissions', { courseId });
	}

	return Promise.reject(error);
});

/**
 * Fetches course manifest and notebook summary, as well as the results
 * of getElementsActivity for the provided pageId
 *
 * @param courseId
 * @param pageId (optional): Include the results of getElementsActivity for the given page in the response
 */
export const getInitialNotebookAsyncState = (
	courseId: number,
	pageId: string = null
): AxiosPromise<InitialNotebookAsyncState> =>
	axiosInstance.get(API.INITIAL_NOTEBOOK_STATE, {
		params: {
			course_id: courseId,
			page_id: pageId,
		},
	});

/**
 * Fetches a hash of summaries for each level of content in the webtext. Used for header table
 * and summary header on page view
 *
 * @param courseId
 * @param pageId (optional): Include the results of getElementsActivity for the given page in the response
 */
export const getNotebookSummary = (
	courseId: number,
	pageId: string = null
): AxiosPromise<NotebookSummary> =>
	axiosInstance.get(API.NOTEBOOK_SUMMARY, {
		params: {
			course_id: courseId,
			page_id: pageId,
		},
	});

/**
 * Gets user respondable activity for the given page
 */
export const getElementsActivity = (
	courseId: number,
	pageId: string
): AxiosPromise<ElementsActivityResponse> =>
	axiosInstance.get(API.ELEMENTS_ACTIVITY, {
		params: {
			page_id: pageId,
			course_id: courseId,
		},
	});

/**
 * Gets user respondable activity for the given page
 */
export const getBackgroundRefreshData = (
	courseId: number,
	pageIds: string[]
): AxiosPromise<RefreshDataResponse> =>
	axiosInstance.post(API.BACKGROUND_REFRESH, {
		page_ids: pageIds,
		course_id: courseId,
	});

/**
 * Gets long-lived access token
 */
export const getAccessToken = (oneTimeToken: string): AxiosPromise<string> =>
	axiosInstance.get(API.ACCESS_TOKEN, {
		headers: {
			Authorization: `Bearer ${oneTimeToken}`,
		},
		responseType: 'text',
	});

export const getCurrentUserData = (): AxiosPromise<{ user: UserInfo }> =>
	axiosInstance.get(API.CURRENT_USER_INFO);

/**
 * Gets user respondable activity for the given page
 */
export const getElementUnreadNotifications = (
	courseId: number,
	elementFamilyId: string
): AxiosPromise<ElementUnreadNotificationsResponse> =>
	axiosInstance.get(API.NOTIFICATIONS, {
		params: {
			course_id: courseId,
			unread: true,
			thing_family_id: elementFamilyId,
		},
	});

export const postInteractionEvent = (event): AxiosPromise =>
	axiosInstance.post(
		API.EVENTS,
		qs.stringify({ ...event, service: 'my_progress', object_name: event.screen }),
		{
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
		}
	);
