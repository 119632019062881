import React, { FC, useMemo } from 'react';
import cn from 'classnames';
import Tippy, { TippyProps } from '@tippyjs/react';
import { followCursor as followCursorPlugin, hideAll } from 'tippy.js';
import 'tippy.js/animations/shift-away-subtle.css';
import styles from './styles';

interface RolloverProps extends TippyProps {
	variant?: 'pane' | 'tooltip' | 'none';
	followCursor?: boolean;
}

const Rollover: FC<RolloverProps> = ({
	variant = 'tooltip',
	followCursor = false,
	children,
	onShow,
	className,
	...tippyProps
}) => {
	const maxWidth = useMemo(() => {
		switch (variant) {
			case 'tooltip':
				return 260;
			case 'pane':
				return 370;
			default:
				return 'none';
		}
	}, [variant]);

	return (
		<Tippy
			trigger={'mouseenter focusin'}
			offset={[0, 10]}
			placement="right-start"
			arrow={false}
			maxWidth={maxWidth}
			followCursor={followCursor}
			plugins={[followCursorPlugin]}
			animation={'shift-away-subtle'}
			duration={[600, 140]}
			delay={[50, null]}
			onShow={(instance) => {
				hideAll({ duration: 0 });
				if (onShow) {
					onShow(instance);
				}
			}}
			{...tippyProps}
			className={cn(className, styles)}
		>
			{children}
		</Tippy>
	);
};

export default Rollover;
