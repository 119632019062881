import React from 'react';

import TimingTableBox from 'components/tables/TimingTableBox';
import styles from 'components/legends/styles';
import { basePage } from 'components/legends/progress_view_legend';

const opened = {
	...basePage,
	totalTimeSpent: 100,
};

const notOpened = {
	...basePage,
	pageOpened: false,
	totalTimeSpent: 0,
};

const TimingViewLegend = (props) => (
	<div className={styles}>
		<span>
			<TimingTableBox pageSummary={opened} />
			<span>Time on page spent clicking, typing, and scrolling</span>
		</span>
		<span>
			<TimingTableBox pageSummary={notOpened} />
			<span>Page not yet visited</span>
		</span>
	</div>
);

export default TimingViewLegend;
