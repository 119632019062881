import { css } from 'emotion';

export default css`
	display: flex;
	flex-direction: column;
	padding: 0.8rem 1rem;
	font-size: 0.7rem;
	background-color: #f1f1f1;
	color: #686868;
	line-height: 1.8;

	.header-row {
		display: flex;
		align-items: center;
		overflow-x: hidden;
		flex-shrink: 0;
	}

	.line {
		margin-left: 16px;
		margin-right: 16px;
	}

	.bold {
		margin-right: 0.25rem;
		font-weight: 500;
		text-transform: uppercase;
	}
`;
