import { useEffect, useRef } from 'react';

import { INTERCOM_APP_ID } from '../constants';
import { UserInfo } from '../types';

declare const window: {
	Intercom?: (
		action: 'update' | 'boot',
		args: {
			app_id?: string;
		} & IntercomProperties
	) => void;
} & Window;

interface IntercomProperties {
	user_id: string;
	user_hash: string;
	name: string;
	email: string;
}

export default function useIntercom(userData: UserInfo) {
	const hasBooted = useRef(false);

	useEffect(() => {
		if (!INTERCOM_APP_ID || userData == null || !window.Intercom) {
			return;
		}

		const intercomProperties: IntercomProperties = {
			user_id: userData.id.toString(),
			email: userData.email,
			name: userData.name,
			user_hash: userData.intercom_hash,
		};

		if (!hasBooted.current) {
			window.Intercom('boot', {
				app_id: INTERCOM_APP_ID,
				...intercomProperties,
			});
			hasBooted.current = true;
		} else {
			window.Intercom('update', intercomProperties);
		}
	}, [userData]);
}
