import { css } from 'emotion';

export default css`
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 22px;
	height: 22px;
	color: #007bff;
	font-family: Helvetica, Arial, sans-serif;
	font-size: 12px;
	white-space: nowrap;

	.line {
		width: 8px;
		height: 2px;
		background-color: black;
	}
`;
