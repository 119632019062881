import React, { FC } from 'react';

interface NotebookInstructorFeedbackProps {
	comments;
}

const NotebookInstructorFeedback: FC<NotebookInstructorFeedbackProps> = ({ comments }) => {
	// Don't show comments if student hasn't opened it in the webtext view yet
	return !comments || !comments.commentSeenAt ? null : <div style={{ marginTop: '20px' }} />;
};

export default NotebookInstructorFeedback;
