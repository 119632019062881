import React, { FC, ReactElement } from 'react';
import { DateTime } from 'luxon';
import { NotebookPageSummary } from '@soomo/lib/notebook/types';
import cn from 'classnames';
import DashedLine from '../DashedLine';
import { getPageScoreType, PageCompletionType } from '../page_type_categorizer';
import { visuallyHidden } from 'styles/utils';
import { scoreFraction, scorePercentage } from '@soomo/lib/notebook/utils';
import styles from './styles';

interface ScoreTableBoxProps {
	pageSummary: NotebookPageSummary;
}

enum ScoreElementTypeAriaLabel {
	'complete' = 'All correct',
	'partially-complete' = 'Low score (some attempts remain)',
	'no-longer-completable' = 'Low score (no attempts remain)',
	'no-longer-attemptable' = 'Due date has passed',
	'not-attempted' = 'Page not yet visited',
}

const ScoreTableBox: FC<ScoreTableBoxProps> = ({ pageSummary }) => {
	const {
		pastDue,
		questionsScored,
		mcAnswerCount,
		resetsRemaining,
		limitedAttempts,
		penaltyPeriodEndsAt: pagePenaltyPeriodEndsAt,
		dueAt: pageDueAt,
	} = pageSummary;

	const dueAt = pageDueAt ? DateTime.fromISO(pageDueAt) : null;
	const penaltyPeriodEndsAt = pageDueAt ? DateTime.fromISO(pagePenaltyPeriodEndsAt) : null;
	const now = DateTime.local();

	const inInvisiblePenaltyPeriod = dueAt ? dueAt <= now && now < penaltyPeriodEndsAt : false;
	const outOfWorkWindow = pastDue && !inInvisiblePenaltyPeriod;
	const allMCWorkComplete =
		limitedAttempts && resetsRemaining === 0 && mcAnswerCount === questionsScored;
	const noLongerCompletable = outOfWorkWindow || allMCWorkComplete;
	const scored = scoreFraction(pageSummary);

	const pageScoreType = getPageScoreType({ noLongerCompletable, scored, ...pageSummary });

	let element: ReactElement | string = null;
	switch (pageScoreType) {
		case 'complete':
		case 'no-longer-completable':
		case 'partially-complete':
			element = scorePercentage(pageSummary);
			break;
		case 'no-longer-attemptable':
			element = <DashedLine />;
			break;
		case 'not-attempted':
			element = <div className="line red" />;
			break;
	}

	return (
		<div className={cn(styles, pageScoreType)}>
			{element}
			<span className={visuallyHidden}>{ScoreElementTypeAriaLabel[pageScoreType]}</span>
		</div>
	);
};

export default ScoreTableBox;
