import { FC } from 'react';

import { ExternalToolElement } from '@soomo/lib/types';
import { ElementAnswer } from '@soomo/lib/notebook/types';
import { GoReactElement } from '@soomo/lib/components/pageElements/GoReact/types';

import { GoReactAnswer } from 'types';

import GoReact from './GoReact';

interface Props {
	element: ExternalToolElement;
	answer: ElementAnswer;
}

const ExternalTool: FC<Props> = (props) => {
	const { element: externalTool, answer } = props;

	if ('score_config' in externalTool) {
		return (
			<GoReact element={externalTool as GoReactElement} answer={answer as GoReactAnswer} />
		);
	}
	return null;
};

export default ExternalTool;
